import Cookies from "js-cookie";

export const setAuthCookie = (v: string) => {
  Cookies.set("auth-token", v, {
    domain: process.env.REACT_APP_COOKIE_DOMAIN
  });
};
export const removeAuthCookie = () => {
  Cookies.remove("auth-token", {
    domain: process.env.REACT_APP_COOKIE_DOMAIN
  });
};
export const getAuthCookie = () => {
  return Cookies.get("auth-token");
};
