import React, { useState } from "react";
import axios from "axios";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { Button } from "djinn-components";
import { errorParser } from "../../utils/errorParser";

interface FormProps {
  getDefaults?: () => object;
  onCompleted: (data: any) => Promise<void>;
  className?: string;
  render: (props: { reset: () => void; control: any; errors: any }) => any;
  buttonTitle?: string;
  endpoint: string;
  method?: "post" | "put";
  successToast?: string;
  helpComp?: any;
  transformPayload?: (data: object) => object;
}

const Form: React.FC<FormProps> = ({
  getDefaults = () => ({}),
  onCompleted,
  className = "",
  render,
  buttonTitle = "Submit",
  endpoint,
  method = "post",
  successToast = "Form saved successfully",
  helpComp = null,
  transformPayload = (data) => data
}) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    control,
    setError
  } = useForm({
    defaultValues: getDefaults()
  });

  const onFormSubmit = async (formData: any) => {
    setLoading(true);

    try {
      const { data } = await axios({
        method,
        url: endpoint,
        data: transformPayload(formData)
      });
      await onCompleted(data);
      alert.show(successToast, { type: "success" });
      reset(formData);
    } catch (error) {
      errorParser(error, setError);
      alert.show("Failed to submit, one or more fields is invalid", {
        type: "error"
      });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className={className}>
      {/* <Prompt
        when={isDirty}
        message="You have unsaved changes are you sure you want to leave?"
      /> */}
      {render({ reset, control, errors })}

      <div className="flex items-center mt-6">
        <div className="w-1/2 flex items-start">{helpComp}</div>

        <div className="flex justify-end items-center w-1/2 flex-grow-0">
          <button
            type="button"
            hidden={!isDirty}
            onClick={() => reset(getDefaults())}
            className="text-sm text-white mr-6 opacity-50 hover:opacity-100 cursor-pointer transition ease-in-out duration-150 focus:outline-none"
          >
            Discard Changes
          </button>
          <div className="w-32 block">
            <Button
              text={buttonTitle}
              type="submit"
              buttonStyle="green"
              disabled={loading || !isDirty}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
