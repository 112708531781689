import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "../../plugins/axios";
import { useNavigate } from "react-router-dom";
import DropField from "../../components/Import/DropField";
import OverviewBox from "../../components/Import/OverviewBox";
import { useAlert } from "react-alert";
import HelpLink from "../../components/HelpLink";
import { formatJsonForApi, validateJsonObject } from "./utils/open-api";

export default function OpenApiImport() {
  const navigate = useNavigate();

  const alert = useAlert();

  const [parsing, setParsing] = useState(false);
  const [parsingValue, setParsingValue] = useState(0);
  const [parsed, setParsed] = useState(false);
  const [uploadedObject, setUploadedObject] = useState<any>(null);

  const [importing, setImporting] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      try {
        const fileReader = new FileReader();
        fileReader.readAsText(acceptedFiles[0], "UTF-8");
        fileReader.onload = (e) => {
          setParsing(true);
          const obj = JSON.parse(e.target?.result as string);

          if (validateJsonObject(obj)) {
            setUploadedObject(formatJsonForApi(obj));
            setParsed(true);
          } else {
            alert.error("The selected file is invalid");
          }
          setParsingValue(100);
          setParsing(false);
        };
      } catch (error) {
        alert.error("The selected file is invalid");
      }
    },
    [alert]
  );

  const onSubmit = async () => {
    setImporting(true);
    try {
      const {
        data: { project }
      } = await axios.post("import-content", uploadedObject);

      navigate(`/project/${project}`);
    } catch (error) {
      alert.error("Failed to import, some of the files content is invalid.");
    }

    setImporting(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/json": [".json"]
    }
  });

  return (
    <form onSubmit={onSubmit} className="w-full max-w-2xl mx-auto">
      {!parsed && (
        <DropField
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
        />
      )}

      {parsing && (
        <div className="text-white text-sm font-medium">
          Uploading and Validating ({parsingValue}%)...
        </div>
      )}

      {parsed && (
        <OverviewBox
          uploadedObject={uploadedObject}
          importing={importing}
          onSubmit={onSubmit}
        />
      )}

      <div className="mt-4 text-left flex flex-col items-start">
        <HelpLink
          text="Need help importing from an Open API spec?"
          link="guides/importing-from-open-api"
        />
        <div className="text-red-700 mt-4 text-sm font-light">
          If you need to convert your open api spec to V3 please see this{" "}
          <a
            href="https://stackoverflow.com/a/59749691/10037470"
            className="font-extrabold text-red-700"
            target="_blank"
            rel="noreferrer noopener"
          >
            post
          </a>
        </div>
      </div>
    </form>
  );
}
