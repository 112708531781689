import axios from "../plugins/axios";

export const getRoles = () => (dispatch: any) => {
  dispatch({
    type: "ROLES_SET_LOADING",
    loading: true
  });
  return axios
    .get("roles")
    .then(({ data }) =>
      dispatch({
        type: "ROLES_SET_LIST",
        list: data
      })
    )
    .catch((re) => Promise.reject(re))
    .finally(() => {
      dispatch({
        type: "USERS_SET_LOADING",
        loading: false
      });
    });
};
