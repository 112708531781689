import React, { useState } from "react";
import axios from "../../../plugins/axios";
import { Button, UserAddIcon, TextInput } from "djinn-components";
import { Controller, useForm } from "react-hook-form";
import { errorParser } from "../../../utils/errorParser";
import { errorStyle } from "../../../constants/styles";
import { useAlert } from "react-alert";
import PermissionsSelectField from "../../Inputs/PermissionsSelect";

const UpdateRoleModal: React.FC<any> = ({ onClose, role }) => {
  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  const getDefaults = () => {
    return {
      name: role.name,
      permissions: role.permissions
    };
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: getDefaults()
  });

  const cancelAddUser = () => {
    onClose();
  };

  const submit = async (values: any) => {
    setLoading(true);

    try {
      await axios.put(`roles/${role.id}`, values);
      onClose();
    } catch (error) {
      errorParser(error, setError);
      alert.show("Failed to update role", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="p-5 px-12">
      <div>
        <div className="mx-auto flex items-center justify-center h-8 w-8">
          <UserAddIcon />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg leading-6 font-medium text-white"
            id="modal-headline"
          >
            Add new role
          </h3>
          <p className="text-sm leading-5 font-light text-xs text-gray-400">
            Enter details bellow to create a new custom role
          </p>

          <div className="mt-10 relative text-left">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  id="name"
                  placeholder="Name"
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.name}
                />
              )}
            />
            {errors.name && errors.name.type == "required" && (
              <span className={errorStyle}>User name is required</span>
            )}

            {errors.name && errors.name.type == "api" && (
              <span className={errorStyle}>
                {errors.name.message?.toString()}
              </span>
            )}
          </div>

          <div className="mt-10 relative text-left">
            <Controller
              name="permissions"
              control={control}
              rules={{ required: true, minLength: 1 }}
              render={({ field: { onChange, value } }) => (
                <PermissionsSelectField
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.permissions}
                />
              )}
            />
            {errors.permissions && (
              <span className={errorStyle}>
                Please select at least one permission to create a new role
              </span>
            )}
            {/* {errors.permissions && errors.permissions.type == "required" && (
              <span className={errorStyle}>User name is required</span>
            )}

            {errors.permissions && errors.permissions.type == "api" && (
              <span className={errorStyle}>{errors.permissions.message}</span>
            )} */}
          </div>
        </div>
      </div>
      <div className="flex mt-10">
        <div className="mr-4">
          <Button
            text="Cancel"
            type="button"
            buttonStyle="red"
            loading={loading}
            disabled={loading}
            onClick={cancelAddUser}
          />
        </div>
        <Button
          text="Update Role"
          type="submit"
          buttonStyle="green"
          loading={loading}
          disabled={loading}
        />
      </div>
    </form>
  );
};

export default UpdateRoleModal;
