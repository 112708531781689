import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import axios from "../../plugins/axios";
import { useNavigate } from "react-router-dom";
import { formatJsonForApi, validateJsonObject } from "./utils/postman";
import DropField from "../../components/Import/DropField";
import OverviewBox from "../../components/Import/OverviewBox";
import { useAlert } from "react-alert";
import HelpLink from "../../components/HelpLink";

export default function PostmanCollectionImport() {
  const navigate = useNavigate();
  const alert = useAlert();

  const [parsing, setParsing] = useState(false);
  const [parsingValue, setParsingValue] = useState(0);
  const [parsed, setParsed] = useState(false);
  const [uploadedObject, setUploadedObject] = useState<any>(null);

  const [importing, setImporting] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      try {
        const fileReader = new FileReader();
        fileReader.readAsText(acceptedFiles[0], "UTF-8");
        fileReader.onload = (e) => {
          setParsing(true);
          const obj = JSON.parse(e?.target?.result as string);

          if (validateJsonObject(obj)) {
            setUploadedObject(formatJsonForApi(obj));
            setParsed(true);
          } else {
            alert.error("Uploaded file does not meet the requirements above.");
          }
          setParsingValue(100);
          setParsing(false);
        };
      } catch (error) {
        alert.error("Uploaded file does not meet the requirements above.");
      }
    },
    [alert]
  );
  const onSubmit = async () => {
    setImporting(true);
    try {
      const {
        data: { project }
      } = await axios.post("import-content", uploadedObject);

      navigate(`/project/${project}`);
    } catch (error) {}

    setImporting(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/json": [".json"]
    }
  });

  return (
    <form onSubmit={onSubmit} className="w-full max-w-2xl mx-auto">
      {!parsed && (
        <DropField
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          isDragActive={isDragActive}
          type="Postman"
          text="Import any Postman v2.1 Collection JSON file by uploading a file here and following the import workflow."
        />
      )}

      {parsing && (
        <div className="text-white text-sm font-medium">
          Uploading and Validating ({parsingValue}%)...
        </div>
      )}

      {parsed && (
        <OverviewBox
          uploadedObject={uploadedObject}
          importing={importing}
          onSubmit={onSubmit}
        />
      )}
      <div className="mt-4 flex items-center justify-between">
        <HelpLink
          text="Need help importing a Postman collection?"
          link="guides/importing-postman-collections"
        />
        <div></div>
      </div>
    </form>
  );
}
