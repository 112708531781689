import React, { useEffect, useState } from "react";
import axios from "../../../plugins/axios";
import { useStripe } from "@stripe/react-stripe-js";
import SelectField from "../../Inputs/SelectField";
import { Link } from "react-router-dom";
import ProIcon from "../../Icons/ProIcon";
import { Button } from "djinn-components";
import { useAlert } from "react-alert";

interface ProjectUpgradeModalProps {
  projectId: number;
  projectVersionId?: number;
  onClose: () => void;
}

const UpgradeProjectModal: React.FC<ProjectUpgradeModalProps> = ({
  projectId,
  projectVersionId,
  onClose
}) => {
  const alert = useAlert();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState<number | null>(null);

  const [billingPeriod, setBillingPeriod] = useState<
  "monthly" | "yearly" | null
  >(null);

  const getPaymentMethods = async () => {
    const { data } = await axios.get("account/payment-methods");
    setPaymentMethods(data);
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const tryUpgrade = async () => {
    return axios.post(`projects/${projectId}/upgrade`, {
      payment_method_id: paymentMethod,
      type: billingPeriod
    });
  };

  const projectUpgradeSuccess = () => {
    alert.show("Project Upgrade Success", {
      type: "success"
    });
    if (projectVersionId) {
      window.location.replace(`/project/${projectVersionId}?upgraded=true`);
      onClose();
    } else {
      onClose();
    }
  };

  const upgrade = async () => {
    if (!stripe) return;
    if (!paymentMethod || !billingPeriod) return;
    setLoading(true);
    try {
      await tryUpgrade();

      projectUpgradeSuccess();
    } catch (err: any) {
      if (err.response.status == 400) {
        const { error } = await stripe.confirmCardPayment(
          err.response.data.client_secret
        );

        if (error) {
          alert.show("Failed to verify your payment details and upgrade", {
            type: "error"
          });
        } else {
          projectUpgradeSuccess();
        }
      }
    }
    setLoading(false);
  };

  return (
    <>
      <form className="p-8 px-14 pt-3">
        <div className="text-center mt-4 mb-10">
          <ProIcon className="w-8 h-8 mx-auto block mb-2" />
          <h3 className="mb-4">Upgrade your project to Docbloc pro</h3>
        </div>

        <div className="my-6">
          <SelectField
            selected={billingPeriod}
            label="Billing Period"
            onSelect={(val) => setBillingPeriod(val)}
            options={[
              {
                key: "Monthly (£22)",
                value: "monthly"
              },
              {
                key: "Yearly (£237) - Save 10%",
                value: "yearly"
              }
            ]}
          />
        </div>

        <div className="my-6">
          <SelectField
            selected={paymentMethod}
            label="Payment Method"
            onSelect={(val) => setPaymentMethod(val)}
            options={paymentMethods.map((pm: any) => ({
              key: `${pm.name} / **** **** **** ${pm.last4}`,
              value: pm.id
            }))}
          />
          <Link to={"/account/billing"} className="p-4 font-medium text-xs">
            Manage payment methods
          </Link>
        </div>
        <div className="my-4">
          <Button
            text="Upgrade Now"
            onClick={upgrade}
            disabled={loading}
            loading={loading}
          />
        </div>
      </form>
    </>
  );
};

export default UpgradeProjectModal;
