import React from "react";
import { RiPencilFill } from "react-icons/ri";
import { Button } from "djinn-components";

export default function EditInviteModal({
  resendUserInvitation,
  deleteUserById,
  deleteUser,
  loading
}: any) {
  return (
    <>
      <form className="p-14 px-14 pt-6" onSubmit={() => {}}>
        <div>
          <div className="mx-auto flex items-center justify-center h-8 w-8">
            <RiPencilFill size={16} color="white" />
          </div>
          <div className="text-center mt-2">
            <h3
              className="text-lg leading-6 font-medium text-white"
              id="modal-headline"
            >
              User Options
            </h3>
            <p className="leading-5 font-light text-xs text-gray-400">
              What would you like to do?
            </p>
          </div>
        </div>
        <div className="flex mt-8">
          <Button
            text="Resend Invite"
            type="button"
            buttonStyle="green"
            loading={loading}
            disabled={loading}
            onClick={resendUserInvitation}
          />
        </div>
        <div className="flex mt-3">
          <Button
            text="Delete Invite"
            type="button"
            onClick={() => deleteUser("DELETE USER", deleteUserById)}
            buttonStyle="red"
            loading={loading}
            disabled={loading}
          />
        </div>
      </form>
    </>
  );
}
