import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { Button, TextInput } from "djinn-components";
import { useAlert } from "react-alert";
import { errorStyle } from "../../constants/styles";
import axios from "../../plugins/axios";

interface VerifyEmailInputs {
  email: string;
}
export default function NotVerifiedScreen() {
  const alert = useAlert();

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm<VerifyEmailInputs>({
    defaultValues: {
      email: email ?? ""
    }
  });

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);

    try {
      await axios.post("/email/resend", values);
      alert.show("Verification email has been resent.", {
        type: "success"
      });
    } catch (error: any) {
      if (error.response.data?.errors?.email)
        setError("email", {
          type: "api",
          message: error.response.data?.errors?.email[0]
        });
      alert.show("Failed to resend email.", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <div className="w-screen h-screen bg-gray-1000 text-white flex flex-col items-center justify-center">
      <form
        className="w-3/6 max-w-md flex flex-col items-center justify-center"
        onSubmit={handleSubmit(onFinish)}
      >
        <a href="https://docbloc.co" target="_blank" rel="noopener noreferrer">
          <img src="/full-logo.svg" alt="Docbloc logo" className="h-8 mb-10" />
        </a>
        <h1 className="text-2xl font-medium mb-2">Verify email address</h1>
        <p className="text-center text-gray-450 text-sm mb-10">
          You need to verify your email address before you can continue. If
          needed, you can resend the verification email below.
        </p>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern:
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
          }}
          render={({ field: { onChange, value } }) => (
            <TextInput
              type="email"
              placeholder="Email"
              value={value}
              onChange={onChange}
              invalid={!!errors.email}
            />
          )}
        />
        {errors.email && errors.email.type === "required" && (
          <span className={errorStyle}>Email address is required</span>
        )}
        {errors.email && errors.email.type === "pattern" && (
          <span className={errorStyle}>Please enter a valid email address</span>
        )}
        {errors.email && errors.email.type === "api" && (
          <span className={errorStyle}>{errors.email.message}</span>
        )}

        <div className="mt-5 flex justify-center items-center">
          <Link
            to="/"
            className="text-gray-450 hover:text-gray-400 text-sm cursor-pointer transition ease-in-out duration-150 mr-4"
          >
            I&lsquo;ve clicked the link
          </Link>
          <div className="w-48">
            <Button
              type="submit"
              loading={loading}
              disabled={loading}
              buttonStyle="green"
              text="Resend Email"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
