import React from "react";
import ReactQuill from "react-quill";
import { TextInput } from "djinn-components";
import HelpLink from "../../HelpLink";
import { useProjectState } from "../../../utils/requiresProject";
import FormHolder from "../FormHolder";
import Form from "../../Form";
import FormFieldWrapper from "../../Form/FormFieldWrapper";

const ProjectSettingsVersionDetails: React.FC = () => {
  const { project, reloadProject } = useProjectState();

  const getDefaults = () => {
    return {
      name: project.name,
      intro: project.intro,
      base_url: project.base_url
    };
  };

  return (
    <FormHolder>
      <Form
        endpoint={`project-versions/${project.id}`}
        method="put"
        buttonTitle="Save Changes"
        successToast="Project settings update successful"
        getDefaults={getDefaults}
        onCompleted={async () => {
          await reloadProject();
        }}
        helpComp={
          <HelpLink
            text="Need help working with project versioning"
            link="guides/project-editor-versions"
          />
        }
        render={({ errors, control }: any) => (
          <div>
            <div className="grid grid-cols-2 gap-8 mb-2 mt-2">
              <div className="mb-6">
                <FormFieldWrapper
                  id="name"
                  label="Version Name"
                  errors={errors}
                  control={control}
                  render={({ onChange, value, invalid }: any) => (
                    <TextInput
                      value={value}
                      placeholder=""
                      onChange={onChange}
                      invalid={invalid}
                    />
                  )}
                />
              </div>
              <div className="mb-6">
                <FormFieldWrapper
                  id="base_url"
                  label="Api Base url"
                  errors={errors}
                  control={control}
                  render={({ onChange, value, invalid }: any) => (
                    <TextInput
                      value={value}
                      placeholder=""
                      onChange={onChange}
                      invalid={invalid}
                    />
                  )}
                />
              </div>
            </div>

            <div className="mb-6">
              <FormFieldWrapper
                id="intro"
                label="Introduction Text"
                errors={errors}
                control={control}
                render={({ onChange, value }: any) => (
                  <ReactQuill theme="snow" value={value} onChange={onChange} />
                )}
              />
            </div>
          </div>
        )}
      />
    </FormHolder>
  );
};

export default ProjectSettingsVersionDetails;
