import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAlert } from "react-alert";
import axios from "../plugins/axios";
import LoadingComponent from "../components/LoadingComponent";

const JoinTeamPage: React.FC = () => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get("invite_token");

  const [loading, setLoading] = useState(true);

  const validateInvitation = useCallback(
    async (token: any) => {
      try {
        await axios.post("accept-invite", {
          token
        });
        navigate("/s/join-team");
      } catch (error) {
        alert.show("Failed to accept invite, please contact support.", {
          type: "error"
        });
      }
      setLoading(false);
    },
    [alert, navigate]
  );

  useEffect(() => {
    if (inviteToken) {
      validateInvitation(inviteToken);
    } else {
      setLoading(false);
    }
  }, [inviteToken, validateInvitation]);

  if (loading) return <LoadingComponent />;

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-800">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src="/icon.png" alt="Doc Bloc" />
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-200">
          There has been an error
        </h2>
      </div>

      <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4">
          <div className="text-md font-medium text-red-800 text-center">
            It seems that your invitation link has either expired or is invalid,
            please double check with the account holder, or contact support if
            you think this is a mistake.
            <br />
            <br />
            <Link
              to="/"
              className="mt-6 font-medium text-white hover:text-gray-300 focus:outline-none focus:underline transition ease-in-out duration-150"
            >
              Back to login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinTeamPage;
