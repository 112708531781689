import React from "react";
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
  DropResult
} from "react-beautiful-dnd";
import {
  arrayMove,
  insertItemAtArrayPosition,
  recursiveObjectFind,
  recursiveSearchAndEdit
} from "../../../utils/object_array.utils";
import ACPAddItem from "./AddItem";
import {
  ACPWidgetType,
  newItemByType,
  ProvideDNDContext,
  useDNDContext
} from "./utils";
import ACPGhostWidget from "./Widgets";

interface AdvancedContentPageBuilderProps {
  value: Array<ACPWidgetType>;
  onChange: (v: object) => void;
}

const Wrapper = ({ children }: any) => {
  return <ProvideDNDContext>{children}</ProvideDNDContext>;
};
const AdvancedContentPageBuilder: React.FC<AdvancedContentPageBuilderProps> = ({
  value,
  onChange
}) => {
  const dndContext = useDNDContext();

  const getListStyle = (isDraggingOver: boolean, draggableStyle = {}) => ({
    ...draggableStyle
  });

  const onDragEnd = ({ destination, draggableId, source }: DropResult) => {
    dndContext.setCurrentDraggable(undefined);
    let newObject = [...value];

    if (!destination?.droppableId) return;
    // if the destination group is the same as the current one then just resorrt
    if (destination?.droppableId === source.droppableId) {
      // if its ungrouped then its the top level so we can just sort.
      if (destination.droppableId === "un-grouped") {
        newObject = arrayMove(newObject, source.index, destination.index);
      } else {
        // moved within a group
        const parentId = destination.droppableId
          .replace("row-", "")
          .replace("column-", "");

        newObject = recursiveSearchAndEdit(
          newObject,
          "id",
          parentId,
          // @ts-expect-error as some dont have the child attr
          "children",
          (vv) => {
            return {
              ...vv,
              children: arrayMove(vv.children, source.index, destination.index)
            };
          }
        );
      }
    } else {
      // moved from one group to another
      const desitinationId = destination.droppableId
        .replace("row-", "")
        .replace("column-", "");

      const sourceId = source.droppableId
        .replace("row-", "")
        .replace("column-", "");

      // get the item
      const itmFound = recursiveObjectFind(
        newObject,
        "id",
        draggableId.replace("draggable-", ""),
        "children"
      );

      if (itmFound) {
        newObject = recursiveSearchAndEdit(
          newObject,
          "id",
          sourceId,
          // @ts-expect-error as some dont have the child attr
          "children",
          (vv) => {
            return {
              ...vv,
              children: vv.children.filter(
                (child: any) => child.id !== itmFound.id
              )
            };
          }
        );
        // add the item to the destination at index
        newObject = recursiveSearchAndEdit(
          newObject,
          "id",
          desitinationId,
          // @ts-expect-error as some don't have the child attr
          "children",
          (vv) => {
            return {
              ...vv,
              children: insertItemAtArrayPosition(
                vv.children,
                itmFound,
                destination.index
              )
            };
          }
        );
      }
    }

    onChange(newObject);
  };
  return (
    <div className="relative">
      <Wrapper>
        <DragDropContext
          onDragEnd={onDragEnd}
          onDragStart={({ draggableId }) => {
            dndContext.setCurrentDraggable(draggableId);
          }}
        >
          <Droppable droppableId={"un-grouped"} mode="standard" type="raw">
            {(
              droppableProvided: DroppableProvided,
              droppableSnapshot: DroppableStateSnapshot
            ) => (
              <div
                ref={droppableProvided.innerRef}
                className="border border-gray-450 rounded-md p-2 relative pb-3"
                style={getListStyle(droppableSnapshot.isDraggingOver)}
                {...droppableProvided.droppableProps}
              >
                {value.map((widget, index) => (
                  <ACPGhostWidget
                    key={index}
                    value={widget}
                    index={index}
                    onChange={(changedVal) => {
                      const nv = [...value];
                      nv[index] = changedVal;
                      onChange(nv);
                    }}
                    onDelete={() => {
                      onChange([...value].filter((fi) => fi.id !== widget.id));
                    }}
                  />
                ))}
                {value.length === 0 && (
                  <div className="w-full text-center text-sm text-gray-400 my-10">
                    Click the plus button below to start adding content to your
                    page.
                  </div>
                )}
                {droppableProvided.placeholder}
                <ACPAddItem
                  onComplete={(t) => {
                    onChange([...value, newItemByType(t)]);
                  }}
                  disabled={["column"]}
                />
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {dndContext.currentDraggable}
      </Wrapper>
    </div>
  );
};

export default AdvancedContentPageBuilder;
