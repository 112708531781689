import React from "react";

const AuthSidebar: React.FC<{ title: string; children: any }> = ({
  children,
  title
}) => {
  return (
    <div className="bg-gray-1000 w-1/3 max-w-sm rounded-r-xl shadow-lg flex flex-col p-8 justify-between text-white pt-14 pl-10">
      <div>
        <a href="https://docbloc.co" target="_blank" rel="noopener noreferrer">
          <img src="/full-logo.svg" alt="Docbloc logo" className="h-8" />
        </a>
      </div>
      <div>
        <h1 className="text-4xl font-medium mb-4">{title}</h1>
        {children}
      </div>
      <div className="text-xs text-gray-500">
        <a
          className="mr-1 hover:text-white cursor-pointer transition ease-in-out duration-150"
          href="https://docbloc.co/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        &nbsp;|&nbsp;
        <a
          className="ml-1 hover:text-white cursor-pointer transition ease-in-out duration-150"
          href="https://docbloc.co/resources/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          Support
        </a>
      </div>
    </div>
  );
};

export default AuthSidebar;
