const INITIAL_STATE = {
  token: null
};

const applySetAccessToken = (state: any, action: any) => ({
  ...state,
  token: action.accessToken
});

function authReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case "AUTH_SET_TOKEN": {
      return applySetAccessToken(state, action);
    }
    default:
      return state;
  }
}

export default authReducer;
