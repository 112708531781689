import React from "react";
import { useBus } from "react-bus";
import { HelpIcon } from "djinn-components";

export default function HelpLink({
  text = "Need help using this?",
  link = "guides",
  showIcon = true
}) {
  const bus = useBus();

  return (
    <div className="text-center text-sm text-gray-450 font-light flex items-center justify-center">
      {showIcon && (
        <HelpIcon
          className="inline-block w-4 h-5 text-green-600 mr-2"
          size={16}
        />
      )}
      {text}
      <a
        className="ml-1 cursor-pointer hover:text-white font-bold"
        onClick={(e) => {
          e.preventDefault();
          bus.emit("open-help-link", link);
        }}
      >
        click here
      </a>
    </div>
  );
}
