import ManageAccountBillingPage from "../components/ManageAccount/BillingAndPayments";
import ManageAccountDetails from "../components/ManageAccount/Details";
import RolesAndPermissions from "../components/ManageAccount/RolesAndPermissions";
import ManageUsersPage from "../components/ManageAccount/Users";

export const settingsRoutes = [
  {
    path: "",
    Component: ManageAccountDetails,
    title: "Team Details"
  },
  {
    path: "billing",
    Component: ManageAccountBillingPage,
    title: "Billing & Payments"
  },

  {
    path: "users",
    Component: ManageUsersPage,
    title: "Users"
  },
  {
    path: "roles-and-permissions",
    Component: RolesAndPermissions,
    title: "Roles & Permissions"
  }
];

export default {};
