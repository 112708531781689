import { Modal } from "djinn-components";
import React, { useState } from "react";
import ACPFieldEditorHolder from "../FieldHolder";
import ACPImageEditModal from "../Modals/Image";
import { ACPFieldComponentProps, ACPImageWidget } from "../utils";

const AcpImageWidgetEditor: React.FC<
ACPFieldComponentProps<ACPImageWidget>
> = ({ value, onChange, index, onDelete }) => {
  const [shown, setShown] = useState(false);

  return (
    <>
      <ACPFieldEditorHolder
        editClick={() => setShown(true)}
        deleteClick={onDelete}
        dndId={value.id}
        index={index}
      >
        <div className="flex items-center justify-center flex-col p-4 h-full">
          {value.imageUrl !== null ? (
            <>
              <img
                src={value.imageUrl}
                className="max-w-40 max-h-12 object-contain"
              />
            </>
          ) : (
            <p>No Image Selected</p>
          )}
        </div>
      </ACPFieldEditorHolder>

      <Modal shown={shown} onClose={() => setShown(false)}>
        <ACPImageEditModal
          initialValue={value.imageUrl ?? undefined}
          onSave={(v) => {
            console.log(v);
            onChange({ ...value, imageUrl: v });
          }}
          onComplete={() => setShown(false)}
        />
      </Modal>
    </>
  );
};

export default AcpImageWidgetEditor;
