import React, { useEffect, useState } from "react";
import { AiFillDatabase, AiOutlineImport } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  ChooseProjectScreen as DjinnProjectSelectScreen
} from "djinn-components";
import { useSelector } from "react-redux";
import axios from "../plugins/axios";
import CreateProjectModal from "../components/Modals/CreateProject";
import CreateSampleProjectModal from "../components/Modals/CreateSampleProject";
import AccountManagerLayout from "../layouts/account";
import { RootState } from "../store";
import { ProjectType } from "../types/models";
import useCheckPermissions from "../utils/useCheckPermission";

const ChooseProjectScreen: React.FC<any> = () => {
  const permissions = useCheckPermissions();
  const navigate = useNavigate();

  const account = useSelector<RootState>(
    ({ user: { currentAccount } }) => currentAccount
  ) as any;

  const [projectsList, setProjectsList] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);

  const [showAddProject, setShowAddProject] = useState(false);
  const [showAddDemoProject, setShowAddDemoProject] = useState(false);

  const getProjectsList = async () => {
    setLoadingProjects(true);
    setProjectsList([]);
    try {
      const { data } = await axios.get("projects");
      setProjectsList(data);
    } catch (error) {}
    setLoadingProjects(false);
  };

  useEffect(() => {
    getProjectsList();
  }, [account]); // eslint-disable-line

  const chooseProject = (projectId: number) =>
    navigate(`/project/${projectId}`);

  const cancelAddProject = () => setShowAddProject(false);

  return (
    <AccountManagerLayout>
      <DjinnProjectSelectScreen
        projects={projectsList.map((project: ProjectType) => ({
          id: project.latest_version_id,
          icon: project.icon,
          title: project.name,
          url: project.url
        }))}
        chooseProject={(id: number | string) => chooseProject(id as number)}
        canCreate={permissions("admin", "up")}
        loadingProjects={loadingProjects}
        createClick={() => {
          setShowAddProject(true);
        }}
        createChildren={
          <>
            <Link
              to="/import/open-api"
              className="p-10 flex flex-col items-center justify-center bg-gray-950 cursor-pointer rounded-md shadow-xl transition ease-in-out duration-150 flex-shrink-0 hover:bg-gray-900"
            >
              <AiOutlineImport size={28} color="white" />
              <h3 className="text-md leading-none font-medium text-white my-3 mt-4">
                Import Content
              </h3>
              <p className="text-sm leading-4 font-light text-gray-450 leading-2 w-10/12 pt-1 text-center">
                You can also create a project from an open API or Postman
                collection
              </p>
            </Link>

            <a
              onClick={() => {
                setShowAddDemoProject(true);
              }}
              className="p-10 flex flex-col items-center justify-center bg-gray-950 cursor-pointer rounded-md shadow-xl transition ease-in-out duration-150 hover:bg-gray-900"
            >
              <AiFillDatabase size={28} color="white" />
              <h3 className="text-md leading-none font-medium text-white my-3 mt-4">
                Demo Project
              </h3>
              <p className="text-sm leading-4 font-light text-gray-450 leading-2 w-10/12 pt-1 text-center">
                Generate a sample project to quickly get started using demo
                content
              </p>
            </a>
          </>
        }
      />

      <Modal
        onClose={cancelAddProject}
        shown={showAddProject}
        dismissible
        contentSize="sm:max-w-2xl sm:w-full"
      >
        <CreateProjectModal
          onComplete={cancelAddProject}
          reload={getProjectsList}
          createDemo={() => {
            setShowAddDemoProject(true);
          }}
        />
      </Modal>

      <Modal
        onClose={() => setShowAddDemoProject(false)}
        shown={showAddDemoProject}
        dismissible
        contentSize="sm:max-w-3xl sm:w-full"
      >
        <CreateSampleProjectModal
          onComplete={() => setShowAddDemoProject(false)}
          reload={() => {}}
        />
      </Modal>
    </AccountManagerLayout>
  );
};

export default ChooseProjectScreen;
