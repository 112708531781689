import React, { useState } from "react";
import { Button } from "djinn-components";
import CustomQuill from "../../CustomQuill";

interface ACPWysiwygEditModalType {
  initialValue?: string;
  onSave: (v: string) => void;
  onComplete: () => void;
}

const ACPWysiwygEditModal: React.FC<ACPWysiwygEditModalType> = ({
  initialValue,
  onSave,
  onComplete
}) => {
  const [value, setValue] = useState(initialValue ?? "");

  const save = async () => {
    onSave(value);
    onComplete();
  };

  return (
    <div className="p">
      <div>
        <div className="mt-2 text-center sm:mt-5">
          <div className="mt-6 relative">
            <CustomQuill
              value={value}
              onChange={(e: string) => {
                setValue(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-8 flex items-center">
        <span className="w-4/10 block mr-4">
          <Button
            text="Cancel"
            buttonStyle="red"
            type="button"
            onClick={onComplete}
          />
        </span>
        <Button
          text={"Save Changes"}
          buttonStyle="green"
          type="button"
          onClick={save}
        />
      </div>
      <div className="mt-10"></div>
    </div>
  );
};

export default ACPWysiwygEditModal;
