import { InviteModel, UserModel } from "../types/models";

export interface UsersReduxState {
  list: UserModel[];
  invites: InviteModel[];
  loading: boolean;
}
const INITIAL_STATE = {
  list: [],
  invites: [],
  loading: false
};

const applySetCurrentUsersList = (state: any, action: any) => ({
  ...state,
  list: action.list
});

const applySetCurrentInvitesList = (state: any, action: any) => ({
  ...state,
  invites: action.list
});

const applySetCurrentLoadingState = (state: any, action: any) => ({
  ...state,
  loading: action.loading
});

function usersReducer(state: UsersReduxState = INITIAL_STATE, action: any) {
  switch (action.type) {
    case "USERS_SET_LIST": {
      return applySetCurrentUsersList(state, action);
    }
    case "INVITES_SET_LIST": {
      return applySetCurrentInvitesList(state, action);
    }
    case "USERS_SET_LOADING": {
      return applySetCurrentLoadingState(state, action);
    }
    default:
      return state;
  }
}

export default usersReducer;
