import React, { useEffect, useState } from "react";
import HelpLink from "../../../components/HelpLink";
import useCheckPermissions from "../../../utils/useCheckPermission";
import { getInvites, getUsers } from "../../../actions/users";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { RolesList, RolesModel, UserModel } from "../../../types/models";
import { Modal, CogsFilledIcon, PlusIcon } from "djinn-components";
import { useProjectState } from "../../../utils/requiresProject";
import EditProjectUserModal from "../../Modals/ProjectUser/Update";
import AddUsersToProjectModal from "../../Modals/ProjectUser/Create";

const ProjectSettingsIAMUsers: React.FC<any> = () => {
  const { project } = useProjectState();
  const [showAddUser, setShowAddUser] = useState(false);

  const usersLoading = useSelector<RootState>(
    ({ users: { loading } }) => loading
  ) as boolean;

  const currentUser: any = useSelector<RootState>(
    ({ user }) => user.currentUser
  );

  const dispatch = useDispatch<AppDispatch>();

  const [showUserOptions, setShowUserOptions] = useState<
  UserModel | undefined
  >();

  const permission = useCheckPermissions();

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getInvites());
  }, [dispatch]);

  const getUserRole = ({
    role,
    project_role
  }: {
    role: RolesList;
    project_role?: RolesModel;
  }) => {
    switch (role) {
      case "owner":
        return "Owner";
      case "admin":
        return "Admin";
      default:
        if (project_role) {
          return `User (${project_role.name})`;
        }
        return "User";
    }
  };

  return (
    <>
      <div className="max-w-5xl mx-auto mt-6">
        <div className="w-full">
          <table
            className="table-auto border-separate mr-0 w-full"
            style={{ borderSpacing: "0 1.2rem" }}
          >
            <thead className="opacity-100 bg-opacity-100">
              <tr>
                <td className="bg-gray-800 text-left text-md leading-4 font-large text-white tracking-wider opacity-100 bg-opacity-100 pl-6">
                  Name
                </td>
                <td className="bg-gray-800 text-left text-md leading-4 font-large text-white tracking-wider">
                  Email
                </td>
                <td className="bg-gray-800 text-left text-md leading-4 font-large text-white tracking-wider">
                  Role
                </td>
                <td className="px-6 bg-gray-800 lg:w-10 w-12" width="5%"></td>
              </tr>
            </thead>
            <tbody className="max-h-124 w-full">
              {project.project.users.map((user: UserModel, index: number) => (
                <tr
                  className={
                    "bg-gray-600 bg-opacity-50 rounded-full mb-6 h-12 pl-6 overflow-hidden w-full"
                  }
                  key={index}
                >
                  <td className="whitespace-no-wrap rounded-l-full text-sm leading-5 font-regular text-white opacity-75 pl-6">
                    {user.name}
                  </td>
                  <td className="whitespace-no-wrap text-sm font-light leading-5 text-white opacity-50 ">
                    {user.email}
                  </td>
                  <td className="whitespace-no-wrap text-sm font-light leading-5 text-white opacity-50 ">
                    {getUserRole(user)}
                  </td>
                  <td width="5%" className="rounded-r-full">
                    {user.id === currentUser.id ||
                    permission("admin", "down") ? (
                      <div className="block w-5 h-5 mr-2 lg:mr-0"></div>
                      ) : (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setShowUserOptions(user);
                        }}
                        className="block h-5 w-5 cursor-pointer text-gray-400 hover:text-white"
                      >
                        <CogsFilledIcon className="block h-5 w-5 cursor-pointer text-gray-400 hover:text-white" />
                      </a>
                      )}
                  </td>
                </tr>
              ))}

              {usersLoading && (
                <tr>
                  <td className="p-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-400">
                    Loading Users...
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
                  <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="w-full flex justify-between items-center mt-4">
          <HelpLink
            text="Need help managing project users?"
            link="guides/users-and-roles"
          />
          <button
            className="h-10 mb-1 bg-green-600 hover:bg-green-700 rounded-full px-4 flex justify-center items-center text-white text-xs font-medium focus:outline-none"
            onClick={(e) => {
              e.preventDefault();
              setShowAddUser(true);
            }}
          >
            Add User <PlusIcon className="ml-3 w-5 h-5" size={18} />
          </button>
        </div>
      </div>
      <Modal
        shown={showAddUser}
        dismissible={true}
        onClose={() => setShowAddUser(false)}
        contentSize="max-w-2xl"
      >
        <AddUsersToProjectModal
          onComplete={() => {
            setShowAddUser(false);
          }}
        />
      </Modal>

      <Modal
        shown={showUserOptions != null}
        dismissible={true}
        onClose={() => setShowUserOptions(undefined)}
      >
        <EditProjectUserModal
          user={showUserOptions!}
          onComplete={() => setShowUserOptions(undefined)}
        />
      </Modal>
    </>
  );
};

export default ProjectSettingsIAMUsers;
