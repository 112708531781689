import React from "react";
import BillingTabsHistory from "./History";
import BillingTabsPaymentMethods from "./PaymentMethods";
import Tabs from "../../Tabs";
import ManageAccountSubscriptions from "./Subscriptions";

const ManageAccountBillingPage: React.FC<any> = () => {
  return (
    <main className="flex-1 relative z-0 overflow-y-auto px-8">
      <div className="max-w-5xl mx-auto flex justify-between bg-gray-600 rounded-lg p-10 relative">
        <Tabs
          defaultActive={0}
          tabs={[
            {
              title: "Subscriptions",
              component: <ManageAccountSubscriptions />
            },
            {
              title: "Billing History",
              component: <BillingTabsHistory />
            },
            {
              title: "Payment Details",
              component: <BillingTabsPaymentMethods />
            }
          ]}
        />
      </div>
    </main>
  );
};

export default ManageAccountBillingPage;
