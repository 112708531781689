import React, { useState } from "react";
import MenuItem from "../../../../components/ProjectEdit/MenuItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getItemsL,
  getItemStyle,
  getListStyle,
  itemFilter,
  getChildItemType
} from "../utils";
import SearchInput from "./SearchInput";
import { LoadingIcon, PlusIcon } from "djinn-components";

const Menu = ({
  search,
  setSearch,
  onDragEnd,
  project,
  setActiveItem,
  dndLoading,
  activeString,
  createItem,
  reloadList
}: any) => {
  const [expanded, setExpanded] = useState([]);

  const MenuHeader = () => {
    return (
      <div className="font-medium text-sm px-3 py-4 flex justify-between items-center">
        <span> Items</span>
        <button
          className="h-8 mb-1 bg-green-600 hover:bg-green-700 rounded-full px-3 flex justify-center items-center text-white text-xs ring-0 ring-transparent focy"
          onClick={createItem}
        >
          New <PlusIcon className="ml-2 w-4 h-4" size={16} />
        </button>
      </div>
    );
  };

  return (
    <div className="relative h-screen flex flex-col overflow-auto flex-center bg-gray-350 text-white w-1/3 max-w-xs px-1 py-4 project-edit-sidebar shadow-md">
      <div className="mb-4 mt-2 px-1">
        <SearchInput
          id="search"
          placeholder="Filter items"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
        />
      </div>
      <div className="section h-full">
        <MenuHeader />
        <div className="p-2">
          {!dndLoading && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={"un-grouped"} isCombineEnabled>
                {(droppableProvided: any, droppableSnapshot: any) => (
                  <div
                    ref={droppableProvided.innerRef}
                    className="section-items rounded"
                    style={getListStyle(droppableSnapshot.isDraggingOver)}
                    {...droppableProvided.droppableProps}
                  >
                    {getItemsL(project.all_items)
                      .filter((item) => itemFilter(item, search))
                      .map((item: any, ind) => (
                        <Draggable
                          key={`i-${getChildItemType(item)}-${item.id}`}
                          index={ind}
                          draggableId={`${getChildItemType(item)}-${item.id}`}
                        >
                          {(draggableProvided: any, draggableSnapshot: any) => (
                            <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              style={getItemStyle(
                                draggableSnapshot.isDragging,
                                draggableSnapshot.combineTargetFor != null,
                                draggableProvided.draggableProps.style,
                                getChildItemType(item)
                              )}
                              data-dragging={
                                draggableSnapshot.isDragging &&
                                !draggableSnapshot.isDropAnimating
                              }
                              className="ring-transparent focus:outline-none focus:border-none outline-none"
                            >
                              <MenuItem
                                item={item}
                                type={getChildItemType(item)}
                                key={`${getChildItemType(item)}-${ind}`}
                                onClick={setActiveItem}
                                search={search}
                                activeString={activeString}
                                expanded={expanded}
                                setExpanded={setExpanded}
                                isGettingDumpedOn={
                                  draggableSnapshot.combineWith
                                }
                                onReload={reloadList}
                              />
                              {draggableProvided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {dndLoading && (
            <div className="w-full h-full top-0 left-0 text-white flex items-center justify-center">
              <LoadingIcon color="#12A667" size={25} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
