import React from "react";
import { Controller } from "react-hook-form";
import { errorStyle } from "../../../constants/styles";
import SelectField from "../../Inputs/SelectField";

const UrlInput = ({
  value,
  onChange,
  url,
  id = "url",
  invalid = false
}: any) => {
  return (
    <label
      className={`w-full flex rounded-r-full shadow-sm bg-gray-700 text-white justify-between items-center sm:text-sm transition duration-500 ease-in-out overflow-hidden ${
        invalid ? "ring-red" : "ring-transparent"
      }`}
      htmlFor={id}
    >
      <div className="w-2/4 bg-gray-500 h-14 flex items-center">
        <span className="text-center px-4 truncate ">{url}</span>
      </div>
      <div className="w-2/4 text-left h-14">
        <input
          id={id}
          name={id}
          value={value}
          onChange={onChange}
          className={"input p-4 bg-transparent pl-4 placeholder-gray-400 form-input outline-none focus:outline-none w-full h-14 ring-0 focus:ring-0 border-none focus:border-none"}
          placeholder="Enter url"
          autoComplete="off"
        />
      </div>
    </label>
  );
};

export default function ProjectEditEndpointInfo({
  project,
  control,
  errors
}: any) {
  return (
    <div>
      <div
        className={`sm:col-span-4 flex rounded-ful ring-1 ${
          errors.endpoint ? "ring-red-700" : "ring-transparent"
        }`}
      >
        <div className="w-32 flex-shrink-0 bg-gray-850 rounded-l-full">
          <Controller
            name="method"
            defaultValue="GET"
            rules={{ required: true }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <SelectField
                selected={value}
                onSelect={onChange}
                transparent={true}
                options={[
                  { key: "GET", value: "GET" },
                  { key: "POST", value: "POST" },
                  { key: "PUT", value: "PUT" },
                  { key: "PATCH", value: "PATCH" },
                  { key: "DELETE", value: "DELETE" },
                  { key: "COPY", value: "COPY" },
                  { key: "HEAD", value: "HEAD" },
                  { key: "OPTIONS", value: "OPTIONS" },
                  { key: "LINK", value: "LINK" },
                  { key: "UNLINK", value: "UNLINK" },
                  { key: "PURGE", value: "PURGE" },
                  { key: "LOCK", value: "LOCK" },
                  { key: "UNLOCK", value: "UNLOCK" },
                  { key: "PROPFIND", value: "PROPFIND" },
                  { key: "VIEW", value: "VIEW" }
                ]}
              />
            )}
          />
        </div>
        <Controller
          name="endpoint"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <UrlInput
              id="endpoint"
              value={value}
              onChange={onChange}
              url={`${project.base_url}/`}
            />
          )}
        />
      </div>

      {errors.endpoint && errors.endpoint.type == "required" && (
        <span className={errorStyle}>A valid endpoint url is required.</span>
      )}

      {errors.endpoint && errors.endpoint.type == "api" && (
        <span className={errorStyle}>{errors.endpoint.message}</span>
      )}

      {errors.method && errors.method.type == "api" && (
        <span className={errorStyle}>{errors.method.message}</span>
      )}
    </div>
  );
}
