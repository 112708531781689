import React from "react";
import ReactQuill from "react-quill";

const CustomToolbar = () => (
  <div id="toolbar">
    <select
      className="ql-header"
      defaultValue={""}
      onChange={(e) => e.persist()}
    >
      <option value="1"></option>
      <option value="2"></option>
      <option value="3"></option>
      <option value=""></option>
    </select>
    <button className="ql-bold" />

    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-link" />
    <button className="ql-code" />
    <button className="ql-code-block" />

    <button className="ql-list" value="ordered" />
    <button className="ql-list" value="bullet" />

    <button type="button" className="ql-clean" />
  </div>
);

const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {}
  },
  clipboard: {
    matchVisual: false
  }
};

const formats = [
  "code-block",
  "bold",
  "code",
  "code-block",
  "link",
  "strike",
  "underline",
  "header",
  "list",
  "image"
];

const CustomQuill: React.FC<any> = ({ value, onChange }) => {
  return (
    <>
      <CustomToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        formats={formats}
        modules={modules}
        onChange={(v, delta, st) => {
          if (st == "user") onChange(v);
        }}
      />
    </>
  );
};

export default CustomQuill;
