import React from "react";
import axios from "axios";
import useConfirmDelete from "../../../../utils/withConfirmDelete";
import { Button } from "djinn-components";
import { useAlert } from "react-alert";

export default function ProjectEditTabSettings({
  theEndpoint,
  closeEndpoint,
  refreshProject,
  setApiLoading,
  apiLoading
}: any) {
  const alert = useAlert();
  const deleteEndpoint = useConfirmDelete();

  const deleteEndpointCallback = async () => {
    setApiLoading(true);
    try {
      await axios.delete(`endpoints/${theEndpoint.id}`);
      refreshProject();
      closeEndpoint();
      alert.show("Page deleted", {
        type: "success"
      });
    } catch (error) {}
    setApiLoading(false);
  };

  return (
    <div className="w-full" style={{ minHeight: "50vh" }}>
      <div className="py-4 mx-auto w-3/4 bg-gray-250 p-6 rounded-lg mt-8">
        <div className="col-span-6 mb-4 flex items-center justify-between">
          <div>
            <p className="mt-2 text-md text-white">Delete Endpoint</p>
            <p className="text-sm text-gray-400">
              This action is not reversible so be sure you want to do it.
            </p>
          </div>
          <span className="w-32 mt-2 pt-1">
            <Button
              text="Delete"
              type="button"
              onClick={() =>
                deleteEndpoint("DELETE PAGE", deleteEndpointCallback)
              }
              buttonStyle="red"
              disabled={apiLoading}
              loading={apiLoading}
            />
          </span>
        </div>
      </div>
    </div>
  );
}
