import React, { useState } from "react";
import { BsFolderPlus } from "react-icons/bs";
import axios from "../../../plugins/axios";
import FieldErrors from "../../FieldErrors";
import { TextInput, Button } from "djinn-components";
import { useAlert } from "react-alert";
import HelpLink from "../../HelpLink";
import { useProjectState } from "../../../utils/requiresProject";

export default function AddEditGroupModal({ group, onComplete }: any) {
  const alert = useAlert();

  const { project, reloadProject } = useProjectState();

  const [editGroup, setEditGroup] = useState(group);

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const createItem = () =>
    axios.post(`groups/${project.id}`, {
      name: editGroup.name
    });

  const submit = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    try {
      await createItem();
      await reloadProject();
      alert.show("Group Saved!", { type: "success" });
      onComplete();
    } catch (error: any) {
      setErrors(error?.response?.data?.errors ?? {});
      alert.show("Failed to save group!", { type: "success" });
    }

    setLoading(false);
  };

  return (
    <form onSubmit={submit} className="p-10">
      <div>
        <div className="mx-auto flex items-center justify-center text-gray-50">
          <BsFolderPlus size={32} color="lightgrey" />
        </div>
        <div className="mt-2 text-center sm:mt-5">
          <h3 className="text-2xl text-white">
            {group.id ? "Edit Group" : "Create group"}
          </h3>

          <div className="mt-2">
            <p className="text-sm text-gray-450 text-center w-3/4 mx-auto">
              Enter the group name below to continue, you can then drag items
              into your new group
            </p>
          </div>
          <div className="mt-6 relative">
            <TextInput
              placeholder="Group Name"
              value={editGroup.name}
              onChange={(e) => {
                setEditGroup({
                  ...editGroup,
                  name: e.target.value
                });
                setErrors({});
              }}
              invalid={Object.keys(errors).includes("name")}
            />
            <FieldErrors errors={errors} id="name" />
          </div>
        </div>
      </div>
      <div className="mt-8 flex items-center">
        <span className="w-4/10 block mr-4">
          <Button
            text="Cancel"
            loading={loading}
            disabled={loading}
            buttonStyle="red"
            type="button"
            onClick={onComplete}
          />
        </span>
        <Button
          text={group.id ? "Update Group" : "Create group"}
          loading={loading}
          disabled={loading}
          buttonStyle="green"
          type="submit"
        />
      </div>
      <div className="mt-10">
        <HelpLink
          text="Need help with groups?"
          link="guides/working-with-groups"
        />
      </div>
    </form>
  );
}
