import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvites, getUsers } from "../../actions/users";
import { Modal, CogsFilledIcon, PlusIcon } from "djinn-components";
import AddUserModal from "../../components/Modals/CreateUser";
import axios from "../../plugins/axios";
import useConfirmDelete from "../../utils/withConfirmDelete";
import useCheckPermissions from "../../utils/useCheckPermission";
import { useAlert } from "react-alert";
import EditUserModal from "../../components/Modals/EditUserModal";
import EditInviteModal from "../../components/Modals/EditUserModal/invite";
import HelpLink from "../../components/HelpLink";
import { RootState } from "../../store";
import { InviteModel, UserModel } from "../../types/models";

const ManageUsersPage: React.FC = () => {
  const dispatch = useDispatch();

  const users = useSelector<RootState>(
    ({ users: { list } }) => list
  ) as UserModel[];

  const invites = useSelector<RootState>(
    ({ users: usersSel }) => usersSel.invites
  ) as InviteModel[];

  const usersLoading = useSelector<RootState>(
    ({ users: { loading } }) => loading
  ) as boolean;

  const currentUser = useSelector<RootState>(
    ({ user }) => user.currentUser
  ) as any;

  const alert = useAlert();

  const [showAddUser, setShowAddUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showUserOptions, setShowUserOptions] = useState<UserModel | undefined>(
    undefined
  );
  const [showInviteOptions, setShowInviteOptions] = useState<
  number | undefined
  >(undefined);

  const [changingRole, setChangingRole] = useState(false);

  const permission = useCheckPermissions();

  const deleteUser = useConfirmDelete();

  useEffect(() => {
    dispatch<any>(getUsers());
    dispatch<any>(getInvites());
  }, [dispatch]);

  const getUserRole = (role: string) => {
    switch (role) {
      case "owner":
        return "Owner";
      case "admin":
        return "Admin";
      default:
        return "User";
    }
  };

  const deleteUserById = () => {
    return axios.delete(`users/${showUserOptions!.id}`).then(() => {
      getUsers();
      getInvites();
      setShowUserOptions(undefined);
    });
  };

  const deleteInviteById = () => {
    return axios.delete(`users/invites/${showInviteOptions}`).then(() => {
      getUsers();
      getInvites();

      setShowInviteOptions(undefined);
    });
  };

  const resendUserInvitation = () => {
    setLoading(true);
    return axios
      .post(`users/resend-invite/${showInviteOptions}`)
      .then(() => {
        alert.show("Invite resent to user!", { type: "success" });
        setShowInviteOptions(undefined);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const switchUserRole = (role: string) => {
    setChangingRole(true);
    return axios
      .put(`users/${showUserOptions!.id}/change-role`, {
        role
      })
      .then(() => {
        getUsers();
        alert.show("User role updated!", { type: "success" });
        setShowUserOptions(undefined);
      })
      .catch(() => {
        alert.show("Failed to update user role!", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
        setChangingRole(false);
      });
  };

  return (
    <>
      <main className="flex-1 relative z-0 overflow-y-auto px-8">
        <div className="max-w-5xl mx-auto">
          <div className="w-full">
            <table
              className="table-auto border-separate mr-0 w-full"
              style={{ borderSpacing: "0 1.2rem" }}
            >
              <thead className="opacity-100 bg-opacity-100">
                <tr>
                  <td className="bg-gray-800 text-left text-md leading-4 font-large text-white tracking-wider opacity-100 bg-opacity-100 pl-6">
                    Name
                  </td>
                  <td className="bg-gray-800 text-left text-md leading-4 font-large text-white tracking-wider">
                    Email
                  </td>
                  <td className="bg-gray-800 text-left text-md leading-4 font-large text-white tracking-wider">
                    Role
                  </td>
                  <td className="px-6 bg-gray-800 lg:w-10 w-12" width="5%"></td>
                </tr>
              </thead>
              <tbody className="max-h-124 w-full">
                {users.map((user, index) => (
                  <tr
                    className={
                      "bg-gray-600 bg-opacity-50 rounded-full mb-6 h-12 pl-6 overflow-hidden w-full"
                    }
                    key={index}
                  >
                    <td className="whitespace-no-wrap rounded-l-full text-sm leading-5 font-regular text-white opacity-75 pl-6">
                      {user.name}
                    </td>
                    <td className="whitespace-no-wrap text-sm font-light leading-5 text-white opacity-50 ">
                      {user.email}
                    </td>
                    <td className="whitespace-no-wrap  text-sm font-light leading-5 text-white opacity-50 ">
                      {getUserRole(user.role)}
                    </td>
                    <td width="5%" className="rounded-r-full">
                      {user.id === currentUser.id ||
                      permission("admin", "down") ? (
                        <div className="block w-5 h-5 mr-2 lg:mr-0"></div>
                        ) : (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setShowUserOptions(user);
                          }}
                          className="block h-5 w-5 cursor-pointer text-gray-400 hover:text-white"
                        >
                          <CogsFilledIcon
                            className="block h-5 w-5 cursor-pointer text-gray-400 hover:text-white"
                            size={20}
                          />
                        </a>
                        )}
                    </td>
                  </tr>
                ))}

                {invites.map((user, index) => (
                  <tr
                    className={
                      "bg-gray-600 bg-opacity-50 rounded-full mb-6 h-12 pl-6 overflow-hidden w-full"
                    }
                    key={index}
                  >
                    <td className="whitespace-no-wrap rounded-l-full text-sm leading-5 font-regular text-white opacity-75 pl-6">
                      {user.name} (Pending)
                    </td>
                    <td className="whitespace-no-wrap text-sm font-light leading-5 text-white opacity-50">
                      {user.email}
                    </td>
                    <td className="whitespace-no-wrap  text-sm font-light leading-5 text-white opacity-50">
                      {getUserRole(user.role)}
                    </td>
                    <td width="5%" className="rounded-r-full">
                      {permission("admin", "down") ? (
                        <div className="block w-5 h-5  mr-2 lg:mr-0"></div>
                      ) : (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            setShowInviteOptions(user.id);
                          }}
                          className="block h-5 w-5 cursor-pointer text-gray-400 hover:text-white"
                        >
                          <CogsFilledIcon
                            className="block h-5 w-5 cursor-pointer text-gray-400 hover:text-white"
                            size={20}
                          />
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
                {usersLoading && (
                  <tr>
                    <td className="p-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-400">
                      Loading Users...
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
                    <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"></td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="w-full flex justify-between items-center mt-4">
            <HelpLink
              text="Need help managing team users?"
              link="guides/managing-team-users"
            />
            <button
              className="h-10 mb-1 bg-green-600 hover:bg-green-700 rounded-full px-4 flex justify-center items-center text-white text-xs font-medium focus:outline-none"
              onClick={(e) => {
                e.preventDefault();
                setShowAddUser(true);
              }}
            >
              Add User <PlusIcon className="ml-3 w-5 h-5" size={20} />
            </button>
          </div>
        </div>
      </main>
      <Modal shown={showAddUser} onClose={() => setShowAddUser(false)}>
        <AddUserModal
          onClose={() => {
            getUsers();
            getInvites();
            setShowAddUser(false);
          }}
        />
      </Modal>

      <Modal
        shown={showUserOptions != null}
        dismissible={true}
        onClose={() => setShowUserOptions(undefined)}
      >
        <EditUserModal
          changingRole={changingRole}
          switchUserRole={switchUserRole}
          deleteUserById={deleteUserById}
          setShowUserOptions={setShowUserOptions}
          deleteUser={deleteUser}
          showUserOptions={showUserOptions}
          loading={loading}
        />
      </Modal>

      <Modal
        shown={showInviteOptions != null}
        dismissible={true}
        onClose={() => setShowInviteOptions(undefined)}
      >
        <EditInviteModal
          resendUserInvitation={resendUserInvitation}
          deleteUserById={deleteInviteById}
          deleteUser={deleteUser}
          setShowInviteOptions={setShowInviteOptions}
          loading={loading}
        />
      </Modal>
    </>
  );
};

export default ManageUsersPage;
