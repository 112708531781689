import React from "react";
import ProfileEdit from "../../components/SettingsParts/ProfileEdit";
import AccountManagerLayout from "../../layouts/account";
import ThemePick from "../../components/SettingsParts/ThemePick";
import NotificationsEdit from "../../components/SettingsParts/NotificationsEdit";
import Tabs from "../../components/Tabs";

export default function EditProfilePage() {
  return (
    <AccountManagerLayout>
      <main className="flex-1 relative z-0 overflow-y-auto p-8">
        <div className="mt-10 max-w-5xl mx-auto flex justify-between bg-gray-600 rounded-lg p-10 relative">
          <Tabs
            defaultActive={0}
            tabs={[
              {
                title: "Profile",
                component: <ProfileEdit />
              },
              {
                title: "Theme",
                component: <ThemePick />
              },
              {
                title: "Notification Settings",
                component: <NotificationsEdit />
              }
            ]}
          />
        </div>
      </main>
    </AccountManagerLayout>
  );
}
