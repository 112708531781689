import axios from "../plugins/axios";

export const getUsers = () => (dispatch: any) => {
  dispatch({
    type: "USERS_SET_LOADING",
    loading: true
  });
  return axios
    .get("users")
    .then(({ data }) =>
      dispatch({
        type: "USERS_SET_LIST",
        list: data
      })
    )
    .catch((re) => Promise.reject(re))
    .finally(() => {
      dispatch({
        type: "USERS_SET_LOADING",
        loading: false
      });
    });
};

export const getInvites = () => (dispatch: any) => {
  dispatch({
    type: "USERS_SET_LOADING",
    loading: true
  });
  return axios
    .get("users/invites")
    .then(({ data }) =>
      dispatch({
        type: "INVITES_SET_LIST",
        list: data
      })
    )
    .catch((re) => Promise.reject(re))
    .finally(() => {
      dispatch({
        type: "USERS_SET_LOADING",
        loading: false
      });
    });
};
