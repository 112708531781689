import React from "react";

const FieldErrors: React.FC<any> = ({ errors = {}, id }) => {
  try {
    return (
      <ul
        className={"mt-1 pl-4 text-red-700 text-left text-xs transition-all duration-500 ease-in-out border-0"}
      >
        {errors[id].map((error: string, index: number) => (
          <li className="block flex justify-start items-center" key={index}>
            {error}
          </li>
        ))}
      </ul>
    );
  } catch (error) {
    return null;
  }
};

export default FieldErrors;
