// @ts-nocheck
import dataTypeChecker from "../../../../utils/dataTypeChecker";

/**
 *
 * Validates that the postman collection uploaded is valid and is v2.1.0
 *
 * @param object obj
 * @return boolean
 */
export const validateJsonObject = (obj) => {
  try {
    if (
      obj.info.schema !=
      "https://schema.getpostman.com/json/collection/v2.1.0/collection.json"
    )
      return false;

    if (!obj.hasOwnProperty("item")) return false;
    if (!obj.hasOwnProperty("info")) return false;

    return true;
  } catch (error) {
    return false;
  }
};

/**
 * Starts parsing of the postman body
 *
 * @param mixed item
 *
 * @return array
 */
export const parsePostmanBodyRaw = (item) => {
  try {
    const obj = JSON.parse(item);
    return breakPostmanRawItems(obj); // eslint-disable-line
  } catch (error) {
    return [];
  }
};

/**
 *
 * Parses the array of postman query parameters
 *
 * @param array data
 * @return array
 */
export const parsePostmanBodyByType = (item) => {
  let type = null;

  try {
    type = item.request.body.mode;
  } catch (error) {}

  switch (type) {
    case "raw":
      return parsePostmanBodyRaw(item.request.body["raw"]); // eslint-disable-line
    case "urlencoded":
    case "formdata":
      return parsePostmanBodyForm(item); // eslint-disable-line
    default:
      return [];
  }
};

/**
 *
 * Parses the array of postman query parameters
 *
 * @param array data
 * @return array
 */
export const parsePostmanQueryParameters = (data) => {
  const list = [];
  const items = data.request?.url?.query ?? [];

  items.forEach((item) => {
    const itemName = item.key.replace(/[\[\]']+/g, "");
    const exists = list.find((li) => li.name === itemName);

    if (!exists) {
      list.push({
        name: itemName,
        type: getObjectType(item.value ?? null), // eslint-disable-line
        description: item.description ?? "",
        required: item.disabled ? false : true
      });
    } else {
      // see todo above
    }
  });

  return list;
};

/**
 *
 * Recursively breaks down an array of parameters to correctly place them
 *
 * @param {*} obj
 * @return array
 */
export const breakPostmanRawItems = (obj) => {
  const ObjType = dataTypeChecker(obj);

  if (ObjType == "Object") {
    const keys = Object.keys(obj);
    const values = Object.values(obj);
    return keys.map((key, ind) => {
      const type = getObjectType(values[ind]); // eslint-disable-line
      return {
        name: key,
        type: type,
        description: "",
        required: true,
        items: getValuesByType(type, values[ind]), // eslint-disable-line
      };
    });
  } else {
    return breakPostmanRawItems(obj[0]);
  }
};

/**
 *
 * Decide what to do for a nested items object or object array.
 * if its an object then we create child parameters based on each key
 * if uts an object array then we only create using the first item
 *
 * @param string type
 * @param mixed value
 */
export const getValuesByType = (type, value) => {
  if (type === "object_array") {
    const rawRes = breakPostmanRawItems(value[0]);
    return rawRes;
  }
  if (type === "object") return breakPostmanRawItems(value);
  return [];
};

/**
 * Does a check to see if it should be returned as a object array or a normal array
 *
 * @param array value
 * @return string
 */
export const checkIsArrayOrObjectArray = (value) => {
  const type = dataTypeChecker(value[0]);
  return type === "Object" ? "object_array" : "array";
};

/**
 *
 * Maps the js object type to a coresponding item that Docbloc accepts and defaults to string
 *
 * @param mixed value the value to check
 * @return string
 */
export const getObjectType = (value) => {
  const type = dataTypeChecker(value);
  switch (type) {
    case "Boolean":
      return "boolean";
    case "Object":
      return "boolean";
    case "Array":
      return checkIsArrayOrObjectArray(value);
    case "Number":
    case "BigInt":
      return "numeric";
    default:
      return "string";
  }
};

/***
 *
 *
 * Parse a postman collection endpoint with a form type
 *
 * @todo detect if its an array, object or object array and parse it correctly
 */
export const parsePostmanBodyForm = (iteml) => {
  const list = [];

  iteml.request.body[iteml.request.body.mode].forEach((item) => {
    const itemName = item.key.replace(/[\[\]']+/g, "");
    const exists = list.find((li) => li.name === itemName);

    if (!exists) {
      // item is a signular or first so can go onto the list
      list.push({
        name: itemName,
        type: getObjectType(item.value ?? null),
        description: item.description ?? "",
        required: item.disabled ? false : true
      });
    } else {
      // see todo above
    }
  });

  return list;
};

/**
 * Parses the items body and query parameters and combines them
 *
 * @param mixed data
 *
 * @return array
 */
export const parseItemParameters = (data) => {
  const getParams = parsePostmanQueryParameters(data);
  const bodyParams = parsePostmanBodyByType(data);
  return [...getParams, ...bodyParams];
};
/**
 *
 * Recursively break the items down into an array.
 * group parameter is passed for child objects that need to be assigned to a group or set to null
 *
 * @param {object} item
 * @param {string|null} group
 *
 * @return array
 */
export const getEndpoints = (item, group = null) => {
  if (item.hasOwnProperty("item")) {
    return item.item.map((ii) => getEndpoints(ii, item));
  }
  return [
    {
      name: item.name,
      content: item.request?.description ?? item.description ?? item.name,
      method: item.request.method.toUpperCase(),
      endpoint: item.request.url?.host[0] ?? item.request.url?.raw,
      group: group?.name,
      group_description: group.description ?? null,
      parameters: parseItemParameters(item)
    }
  ];
};

/**
 *
 *
 * The main entrypoint for formating an uploaded postman collection
 *
 * @param {mixed} data
 *
 * @return object
 */
export const formatJsonForApi = (data) => {
  const groups = [];
  const groupDescriptions = [];
  const endpoints = [];

  const endpointsRaw = data.item.map(getEndpoints);

  const cleanItem = (item) => {
    let endp = item.endpoint;

    data.variable.forEach((vari) => {
      try {
        endp = endp.replace(`{{${vari.key}}}`, "");
      } catch (error) {}
    });

    return {
      ...item,
      endpoint: endp
    };
  };

  const recursiveCheck = (item) => {
    if (Array.isArray(item)) {
      item.forEach(recursiveCheck);
    } else {
      groups.push(item.group);

      if (item.group_description)
        groupDescriptions.push({ [`${item.group}`]: item.group_description });

      endpoints.push(cleanItem(item));
    }
  };

  endpointsRaw.forEach(recursiveCheck);

  const uniqueGroupDes = {};

  groupDescriptions.forEach((grp) => {
    uniqueGroupDes[Object.keys(grp)[0]] = Object.values(grp)[0];
  });

  const urlVars = data.variable.filter(
    (variable) =>
      variable.value == "http:" ||
      variable.value == "https:" ||
      variable.key.includes("url") ||
      variable.key.includes("uri")
  );

  return {
    project_name: data.info.name,
    project_intro: data.info.description ?? data.info.name,
    base_url: urlVars.length ? urlVars[0].value : "https://domain.com/api",
    endpoint_groups: [...new Set(groups)],
    group_content: uniqueGroupDes,
    endpoints
  };
};
