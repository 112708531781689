import React, { useEffect, useState } from "react";
import axios from "../../../plugins/axios";
import { SubscriptionModel } from "../../../types/models";
import dateFormat from "dateformat";
import useConfirmDelete from "../../../utils/withConfirmDelete";
import { Avatar, Modal } from "djinn-components";
import UpgradeProjectModal from "../../Modals/UpgradeProjectModal";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../../utils/stripe.utils";
import { useAlert } from "react-alert";

const ManageAccountSubscriptions: React.FC<any> = () => {
  const alert = useAlert();
  const [subscriptions, setSubscriptions] = useState<SubscriptionModel[]>([]);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);
  const [showUpgradeProject, setShowUpgradeProject] = useState<number | null>(
    null
  );

  const deleteSubscription = useConfirmDelete(
    "Confirm Cancellation",
    "Are you sure you want to cancel this subscription, any Docbloc Pro settings you have enabled will be removed."
  );

  const getSubscriptions = async () => {
    const { data } = await axios.get("account/subscriptions");
    setSubscriptions(data);
    setSubscriptionsLoading(false);
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  const cancelSubscriptionByProjectId = async (projectId: number | string) => {
    try {
      await axios.delete(`account/subscriptions/${projectId}`);
      getSubscriptions();
      alert.success("Your subscription has been cancelled.");
    } catch (error) {
      alert.success("Failed to cancel your subscription");
    }
  };

  return (
    <div>
      <div className="w-full mt-10">
        <table
          className="table-auto border-separate mr-0 w-full"
          style={{ borderSpacing: "0 1.2rem" }}
        >
          <thead className="opacity-100 bg-opacity-100">
            <tr>
              <td className="text-left text-md leading-4 font-large text-white tracking-wider opacity-100 bg-opacity-100 pl-14">
                Project
              </td>
              <td className="text-left text-md leading-4 font-large text-white tracking-wider">
                Subscription
              </td>
              <td className="text-left text-md leading-4 font-large text-white tracking-wider">
                Ends At
              </td>
              <td className="text-left text-md leading-4 font-large text-white tracking-wider">
                Renews At
              </td>
              <td className="px-6 lg:w-10 w-12" width="5%"></td>
            </tr>
          </thead>

          <tbody className="max-h-124 w-full">
            {subscriptions.map((subscription, index) => (
              <tr
                className={
                  "bg-gray-700 bg-opacity-50 rounded-full mb-6 h-12 pl-6 overflow-hidden w-full"
                }
                key={index}
              >
                <td className="whitespace-no-wrap rounded-l-full text-sm leading-5 font-regular text-white opacity-75 pl-1">
                  <div className="flex items-center justify-start">
                    <Avatar
                      img={subscription.project_icon}
                      text={subscription.project_name}
                    />
                    <span className="ml-3">{subscription.project_name}</span>
                  </div>
                </td>
                <td className="whitespace-no-wrap text-sm font-light leading-5 text-white opacity-50 ">
                  {subscription.subscription?.status
                    ? `Docbloc Pro (${subscription.subscription.period})`
                    : "No Active Subscriptions"}
                </td>
                <td className="whitespace-no-wrap text-sm font-light leading-5 text-white opacity-50 ">
                  {subscription.subscription?.ends_at ? (
                    <>
                      {dateFormat(
                        subscription.subscription.ends_at,
                        "d mmm yyyy"
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td className="whitespace-no-wrap text-sm font-light leading-5 text-white opacity-50 ">
                  {subscription.subscription?.next_billing_date ? (
                    <>
                      {dateFormat(
                        subscription.subscription.next_billing_date,
                        "d mmm yyyy"
                      )}{" "}
                      <strong className="text-white font-bold">
                        (£{subscription.subscription.cost / 100})
                      </strong>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td width="10%" className="rounded-r-full">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block h-5 w-10 cursor-pointer text-gray-400 hover:text-white text-sm font-bold"
                    onClick={() => {
                      if (subscription.subscription) {
                        deleteSubscription("CANCEL SUBSCRIPTION", () =>
                          cancelSubscriptionByProjectId(
                            subscription.subscription!.id
                          )
                        );
                      } else {
                        setShowUpgradeProject(subscription.project_id);
                      }
                    }}
                  >
                    {subscription.subscription &&
                      !subscription.subscription.ends_at && (
                        <p className="block h-5 w-10 text-red-700 text-right">
                          CANCEL
                        </p>
                    )}
                    {subscription.subscription &&
                      subscription.subscription.ends_at != null && (
                        <p className="block h-5 w-10 text-green-600 text-right">
                          RESTART
                        </p>
                    )}
                    {!subscription.subscription && (
                      <p className="block h-5 w-10 text-green-600 text-right">
                        UPGRADE
                      </p>
                    )}
                  </a>
                </td>
              </tr>
            ))}

            {subscriptionsLoading && (
              <tr>
                <td className="p-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-400">
                  Loading Subscriptions...
                </td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
                <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-400"></td>
                <td className="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium"></td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Modal
        shown={showUpgradeProject !== null}
        dismissible={true}
        onClose={() => setShowUpgradeProject(null)}
      >
        <Elements stripe={stripePromise}>
          {showUpgradeProject !== null && (
            <UpgradeProjectModal
              onClose={async () => {
                await getSubscriptions();
                setShowUpgradeProject(null);
              }}
              projectId={showUpgradeProject}
            />
          )}
        </Elements>
      </Modal>
    </div>
  );
};

export default ManageAccountSubscriptions;
