import { Modal } from "djinn-components";
import React, { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import ACPGhostWidget from ".";
import ACPAddItem from "../AddItem";
import ACPFieldEditorHolder from "../FieldHolder";
import ACPRowEditModal from "../Modals/Row";
import {
  ACPFieldComponentProps,
  ACPRowWidget,
  ACPWidgetType,
  newItemByType,
  useDNDContext
} from "../utils";

const getListStyle = (isDraggingOver: boolean, draggableStyle = {}) => ({
  ...draggableStyle
});

const AcpRowWidgetEditor: React.FC<ACPFieldComponentProps<ACPRowWidget>> = ({
  value,
  onChange,
  index,
  onDelete
}) => {
  const [shown, setShown] = useState(false);

  const dndContext = useDNDContext();

  return (
    <>
      <ACPFieldEditorHolder
        editClick={() => setShown(true)}
        deleteClick={onDelete}
        dndId={value.id}
        index={index}
      >
        <div className={`grid grid-cols-${value.config.columns} p-2 gap-4`}>
          {Array.from({ length: value.config.columns }, (_, i) => i).map(
            (itemIndex) => (
              <div key={itemIndex}>
                <Droppable
                  key={`${value.type}-${value.id}`}
                  droppableId={`${value.type}-${value.id}`}
                  isDropDisabled={
                    !!value.children[itemIndex] ||
                    dndContext.currentDragable === `draggable-${value.id}`
                  }
                  isCombineEnabled
                  direction="horizontal"
                  type="widgets"
                >
                  {(droppableProvided, droppableSnapshot) => (
                    <div
                      ref={droppableProvided.innerRef}
                      style={getListStyle(droppableSnapshot.isDraggingOver)}
                      {...droppableProvided.droppableProps}
                      className="h-full"
                    >
                      {value.children[itemIndex] &&
                      value.children[itemIndex] !== null ? (
                        <ACPGhostWidget
                          index={itemIndex}
                          value={value.children[itemIndex] as ACPWidgetType}
                          onChange={(changedVal) => {
                            const nv = value;
                            nv.children[itemIndex] = changedVal;
                            onChange(nv);
                          }}
                          onDelete={() => {
                            const nv = value;
                            nv.children[itemIndex] = null;
                            onChange(nv);
                          }}
                        />
                        ) : (
                        <>
                          {droppableSnapshot.isDraggingOver ? (
                            <div className="border rounded-md border-gray-450 h-full flex items-center justify-center flex-col p-6 text-center text-sm border-dashed">
                              Drop item here
                            </div>
                          ) : (
                            <div className="border rounded-md border-gray-450 h-full flex items-center justify-center flex-col p-6 text-center text-sm">
                              Drag widget here or add below
                              <ACPAddItem
                                onComplete={(t) => {
                                  const newChilds = [...value.children];
                                  newChilds[itemIndex] = newItemByType(t);
                                  onChange({
                                    ...value,
                                    children: newChilds
                                  });
                                }}
                                disabled={["row"]}
                              />
                            </div>
                          )}
                        </>
                        )}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            )
          )}
        </div>
      </ACPFieldEditorHolder>
      <Modal shown={shown} onClose={() => setShown(false)}>
        <ACPRowEditModal
          initialValue={{ ...value }}
          onSave={(v) => {
            onChange(v);
            console.log(v);
          }}
          onComplete={() => setShown(false)}
        />
      </Modal>
    </>
  );
};

export default AcpRowWidgetEditor;
