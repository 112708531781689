import React from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import { errorStyle, labelStyle } from "../../../../constants/styles";
import { Controller } from "react-hook-form";

export default function ProjectEditTabResponses({
  errors,
  control,
  setResponseValid,
  responseValid,
  theResponse,
  setTheResponse
}: any) {
  const responseValueChange = (
    code: string,
    callback: (val: object | null) => void
  ) => {
    setTheResponse(`${code}`);

    if (code == "") {
      setResponseValid(true);
      callback(null);
      return;
    }
    try {
      callback(JSON.parse(code));
      setResponseValid(true);
    } catch (error) {
      setResponseValid(false);
    }
  };

  return (
    <>
      <div className="mt-8 sm:col-span-6">
        <label htmlFor="request" className={labelStyle}>
          Response Sample (JSON)
        </label>
        <div className="mt-1 rounded-t-3xl p-2 bg-gray-600">
          <Controller
            name="response"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange } }) => (
              <Editor
                value={theResponse}
                onValueChange={(v) => responseValueChange(v, onChange)}
                preClassName="form-textarea block w-full transition duration-150 ease-in-out sm:leading-5 p-2 focus:outline-none"
                highlight={(code) =>
                  highlight(`${code}`, languages.javascript, "javascript")
                }
                padding={10}
                style={{
                  fontFamily: "monospace",
                  fontSize: 14,
                  fontWeight: "100",
                  minHeight: "300px"
                }}
                tabSize={2}
              />
            )}
          />
        </div>

        <div className="rounded-b-3xl bg-gray-800 p-6 text-gray-400 text-sm">
          JSON format valid:{" "}
          <span
            className={`font-bold text-white capitalize ${
              responseValid ? "text-green-400" : "text-red-600"
            }`}
          >
            {responseValid.toString()}
          </span>
        </div>

        {errors.response && errors.response.type == "api" && (
          <span className={errorStyle}>{errors.response.message}</span>
        )}
      </div>
    </>
  );
}
