import React, { useState } from "react";
import { Button } from "djinn-components";
import ParameterFieldDetailsWindow from "./DetailsWindow";

const AddItemModal: React.FC<any> = ({ onResolve }) => {
  const [loading, setLoading] = useState(false);

  const [newItem, setNewItem] = useState({
    name: "field_name",
    type: "string",
    description: "field_name",
    required: false,
    items: []
  });

  const updateParamValue = (val: any, key: any) => {
    const newObj = JSON.parse(JSON.stringify(newItem));
    newObj[key] = val;
    setNewItem(newObj);
  };

  const onResolveIn = (value: any) => {
    setLoading(true);
    onResolve(value);
  };

  return (
    <div className="absolute bg-gray-750 w-full h-full top-0 left-0 z-10 rounded-md p-6 flex flex-col items-center justify-center">
      <div className="w-3/4 mx-auto">
        <ParameterFieldDetailsWindow
          item={newItem}
          updateParamValue={updateParamValue}
        />
      </div>
      <div className="flex items-center justify-center">
        <Button
          type="button"
          onClick={() => onResolveIn(false)}
          disabled={loading}
          buttonStyle="red"
          loading={loading}
          text="Cancel"
        />
        <div className="w-10 h-10"></div>
        <Button
          type="button"
          buttonStyle="green"
          onClick={() => onResolveIn(newItem)}
          disabled={loading || !newItem.name || !newItem.description}
          loading={loading}
          text="Save"
        />
      </div>
    </div>
  );
};

export default AddItemModal;
