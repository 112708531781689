import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AccountManagerLayout as DjinnAccountManagerLayout,
  Avatar,
  ThreeDotsIcon
} from "djinn-components";
import { RootState } from "../store";
import { AccountModel } from "../types/models";
import { useAuth } from "../utils/auth/useAuth";

const AccountManagerLayout = ({ children }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { fetchUser, logout } = useAuth();

  const user = useSelector<RootState>(
    ({ user: { currentUser } }) => currentUser
  ) as any;

  const getLoadedAcco = () => {
    if (window.localStorage.getItem("Acc0")) {
      const st = user.accounts.find(
        (acc: AccountModel) =>
          acc.id.toString() == window.localStorage.getItem("Acc0")
      );
      return st ?? user.accounts[0];
    }
    if (!user.accounts.length) {
      navigate("/create-team");
      return;
    }
    return user.accounts[0];
  };

  const [selectedTeam, setSelectedTeam] =
    useState<AccountModel>(getLoadedAcco());

  useEffect(() => {
    if (selectedTeam) {
      window.localStorage.setItem("Acc0", selectedTeam.id.toString());
      dispatch({
        type: "USER_SET_CURRENT_ACCOUNT",
        currentAccount: selectedTeam
      });
      fetchUser();
    }
  }, [selectedTeam, dispatch]); // eslint-disable-line

  return (
    <DjinnAccountManagerLayout
      logo="/full-logo.svg"
      Link={({ className = "", to, children: linkChildren }: any) => (
        <Link to={to}>
          <a className={className}>{linkChildren}</a>
        </Link>
      )}
      user={{
        avatar: user.avatar,
        name: user.name
      }}
      routes={{
        chooseProject: "/choose-project",
        editProfile: "/profile",
        manageAccount: "/account"
      }}
      logoutClick={async () => {
        logout();
      }}
      dropdownTeams={
        <>
          <div
            className="px-4 pb-1 pt-3 text-xs flex items-center justify-between"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <p className="text-gray-400">{user.email}</p>
            <button
              onClick={() => {
                navigate("/create-team");
              }}
              className="text-gray-400 hover:text-white cursor-pointer"
            >
              <ThreeDotsIcon size={16} />
            </button>
          </div>
          {user.accounts?.map((account: AccountModel, key: number) => (
            <div
              className={`${
                selectedTeam.id === account.id
                  ? "opacity-100"
                  : "opacity-50 hover:opacity-95"
              } p-3 text-gray-200 hover:bg-gray-900 cursor-pointer flex justify-between items-center hover:text-gray-300 transition ease-in-out duration-1503 `}
              onClick={() => {
                setSelectedTeam(account);
                if (location.pathname.includes("/account")) {
                  history.go(0);
                }
              }}
              key={key}
            >
              <div className="flex justify-start items-center">
                <Avatar img={account.logo} text={account.name} />

                <div className="ml-3">
                  <h4 className="text-md font-lg">{account.name}</h4>
                  <p className="text-sm text-gray-450">
                    {account.projects} Project
                    {account.projects !== 1 ? "s" : ""}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </>
      }
    >
      {children}
    </DjinnAccountManagerLayout>
  );
};
export default AccountManagerLayout;
