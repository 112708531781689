import React, { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../plugins/axios";
import { Button, TextInput } from "djinn-components";
import useConfirmDelete from "../../utils/withConfirmDelete";
import CustomQuill from "../Inputs/CustomQuill";
import { errorStyle, labelStyle } from "../../constants/styles";
import Tabs from "../Tabs";
import { Controller, useForm } from "react-hook-form";
import { errorParser } from "../../utils/errorParser";
import LoadingComponent from "../LoadingComponent";
import HelpLink from "../HelpLink";
import useCheckProjectPermissions from "../../utils/useCheckProjectPermission";
import { useProjectState } from "../../utils/requiresProject";

const ProjectEditGroup = () => {
  const { project, reloadProject: refreshProject } = useProjectState();
  const params = useParams<any>();
  const navigate = useNavigate();
  const alert = useAlert();
  const permission = useCheckProjectPermissions(project);
  const deleteGroup = useConfirmDelete();

  const [currentGroup, setCurrentGroup] = useState<any>({});
  const [apiLoading, setApiLoading] = useState(false);
  const [groupLoaded, setGroupLoaded] = useState(false);

  const getDefaults = useCallback(() => {
    return {
      name: currentGroup.name ?? "",
      content: currentGroup.content ?? ""
    };
  }, [currentGroup]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    formState,
    reset
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: getDefaults()
  });

  useEffect(() => {
    reset(getDefaults());
  }, [currentGroup, getDefaults, reset]);

  const resetState = () => {
    setCurrentGroup({});
    setApiLoading(false);
  };

  useEffect(() => {
    resetState();
    try {
      const group = project.groups.find(
        (itGroup: any) => itGroup.id == parseInt(params.groupId as string)
      );
      if (!group) throw new Error();
      setCurrentGroup(group);
      setGroupLoaded(true);
    } catch (error) {
      navigate(`/project/${params.project}/edit`);
    }
  }, [navigate, params.groupId, params.project, project.groups]);

  const updateItem = (values: any) =>
    axios.put(`groups/${currentGroup.id}`, values);

  const formSubmit = async (values: any) => {
    setApiLoading(true);

    try {
      const { data } = await updateItem(values);

      alert.show("Group updated!", {
        type: "success"
      });

      setCurrentGroup(data);
    } catch (error) {
      errorParser(error, setError);
      alert.show("Failed to save!", { type: "error" });
    }

    setApiLoading(false);
  };
  const closeGroup = () => {
    navigate(`/project/${params.project}/edit`);
  };

  const deleteItem = async (with_children = false) => {
    setApiLoading(true);
    try {
      await axios.delete(
        `groups/${currentGroup.id}${with_children && "?with_children=true"}`
      );
      refreshProject();
      closeGroup();
      alert.show("Group Deleted", {
        type: "success"
      });
    } catch (error) {}
    setApiLoading(false);
  };

  const getTabs = () => {
    const tabs = [
      {
        title: "Group details",
        component: (
          <div className="mt-8">
            <div className="">
              <div className="sm:col-span-4">
                <label htmlFor="name" className={labelStyle}>
                  Group name
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: true
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        value={value}
                        placeholder="Enter group title"
                        onChange={onChange}
                        invalid={!!errors.name}
                      />
                    )}
                  />
                  {errors.name && errors.name.type == "required" && (
                    <span className={errorStyle}>Project name is required</span>
                  )}

                  {errors.name && errors.name.type == "api" && (
                    <span className={errorStyle}>
                      {errors.name.message?.toString()}
                    </span>
                  )}
                </div>
              </div>

              <div className="mt-6 mb-4 sm:col-span-6">
                <label htmlFor="content" className={labelStyle}>
                  Intro content
                </label>
                <div className="mt-1">
                  <Controller
                    name="content"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CustomQuill value={value} onChange={onChange} />
                    )}
                  />

                  {errors.content && errors.content.type == "api" && (
                    <span className={errorStyle}>
                      {errors.content.message?.toString()}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }
    ];

    if (permission("admin"))
      tabs.push({
        title: "Group settings",
        component: (
          <div className="w-full" style={{ minHeight: "50vh" }}>
            <div className="py-4 mx-auto w-3/4 bg-gray-250 p-6 rounded-lg mt-8">
              <div className="col-span-6 mb-4 flex items-center justify-between">
                <div>
                  <p className="mt-2 text-md text-white">Delete Group</p>
                  <p className="text-sm text-gray-400">
                    This will delete the group and all items will become
                    un-grouped, this action is not reversible.
                  </p>
                </div>
                <span className="w-32 mt-2 pt-1">
                  <Button
                    text="Delete"
                    type="button"
                    onClick={() => deleteGroup("DELETE GROUP", deleteItem)}
                    buttonStyle="red"
                    disabled={apiLoading}
                    loading={apiLoading}
                  />
                </span>
              </div>
            </div>

            <div className="py-4 mx-auto w-3/4 bg-gray-250 p-6 rounded-lg mt-6">
              <div className="col-span-6 mb-4 flex items-center justify-between">
                <div>
                  <p className="mt-2 text-md text-white">
                    Delete Group &amp; Items
                  </p>
                  <p className="text-sm text-gray-400">
                    This will delete the group and all items, this action is not
                    reversible.
                  </p>
                </div>
                <span className="w-32 mt-2 pt-1">
                  <Button
                    text="Delete"
                    type="button"
                    onClick={() =>
                      deleteGroup("DELETE GROUP", () => deleteItem(true))
                    }
                    buttonStyle="red"
                    disabled={apiLoading}
                    loading={apiLoading}
                  />
                </span>
              </div>
            </div>
          </div>
        )
      });

    return tabs;
  };

  if (!groupLoaded)
    return (
      <div>
        <LoadingComponent />
      </div>
    );

  return (
    <div className="mx-auto w-full max-w-4xl pt-10 mb-10">
      <h1 className="text-lg font-light">
        Editing Group:{" "}
        <strong className="font-medium">{currentGroup.name}</strong>
      </h1>

      <p className="text-sm max-w-xl font-light text-gray-450">
        Groups are best used for keeping your documentation simple and
        structured, for example keeping all endpoints, pages and models relating
        to authentication in the same group.
      </p>

      <form
        onSubmit={handleSubmit(formSubmit)}
        className="bg-gray-250 w-full rounded-lg mt-10 bg-opacity-50 p-10 pb-6"
      >
        {/* <Prompt
          when={formState.isDirty}
          message="You have unsaved changes are you sure you want to leave?"
        /> */}
        <Tabs tabs={getTabs()} />

        <div className="w-full flex justify-between items-center mt-6">
          <HelpLink
            text="Need help with editing groups?"
            link="guides/working-with-groups"
          />
          <div className="flex items-center">
            <button
              type="button"
              hidden={!formState.isDirty}
              onClick={() => reset(getDefaults())}
              className="text-sm text-white mr-6 opacity-50 hover:opacity-100 cursor-pointer transition ease-in-out duration-150 focus:outline-none"
            >
              Discard Changes
            </button>
            <div className="w-32 block">
              <Button
                text={"Save changes"}
                type="submit"
                buttonStyle="green"
                disabled={apiLoading || !formState.isDirty}
                loading={apiLoading}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ProjectEditGroup;
