import React from "react";
import { Button, ExclamationCircle } from "djinn-components";

const ItemBlock = ({ title, value, last = false, desc = "" }: any) => {
  return (
    <div className={`py-4 ${!last && "border-b border-gray-600"}`}>
      <p className="text-md font-medium leading-6 text-white">
        <span className="capitalize">{title}</span>:{" "}
        <strong className="font-light text-gray-400">{value}</strong>
      </p>
      <p className="text-gray-450 text-sm font-light">{desc}</p>
    </div>
  );
};

export default function OverviewBox({
  uploadedObject,
  importing,
  onSubmit,
  cancelImport = () => {
    window.location.reload();
  }
}: any) {
  return (
    <>
      <div>
        <div className="rounded-xl p-5 bg-gray-1000 mt-8">
          <p className="mt-3 mb-4 text-sm leading-5 text-gray-200 flex items-center justify-center">
            <ExclamationCircle className="text-green-600 mr-2" />
            File upload successful, the following content will be imported
          </p>

          <ItemBlock
            title="title"
            value={uploadedObject?.project_name ?? "New Project"}
            desc="This is the title of your new project, it can be changed in the project settings"
          />
          <ItemBlock
            title="base url"
            value={uploadedObject?.base_url ?? "/"}
            desc="The base URL for your api, this can be edited from the project settings."
          />

          <ItemBlock
            title="groups to import"
            value={uploadedObject?.endpoint_groups?.length ?? "0"}
            desc="The groups that will be included in your import. You can manage project content after import."
          />
          <ItemBlock
            title="endpoints to import"
            last={true}
            value={uploadedObject?.endpoints?.length ?? "0"}
            desc="The endpoints that will be included in your import. You can manage project content after import."
          />
        </div>
      </div>
      <div className="mt-8 text-right flex items-center justify-end">
        <button
          type="button"
          onClick={cancelImport}
          className="text-gray-450 font-medium text-sm mr-6"
        >
          Cancel
        </button>
        <span className="block w-1/4">
          <Button
            text="Save & Import"
            loading={importing}
            disabled={importing}
            buttonStyle="green"
            type="button"
            onClick={onSubmit}
          />
        </span>
      </div>
    </>
  );
}
