import axios from "axios";
import React, { useState } from "react";
import { Button, ServerStackIcon } from "djinn-components";
import { useBus } from "react-bus";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

export default function CreateSampleProjectModal({ onComplete, reload }: any) {
  const alert = useAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const bus = useBus();

  const startGenerating = async () => {
    setLoading(true);
    try {
      const {
        data: { project }
      } = await axios.post("/projects/generate");
      await reload();
      onComplete();
      alert.show("Demo project created!", { type: "success" });
      navigate(`/project/${project}`);
    } catch (error) {
      onComplete();
      alert.show("Failed to generate project!", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="p-5 px-12">
        <div className="mx-auto">
          <div>
            <div className="mx-auto flex items-center justify-center h-10 w-10">
              <ServerStackIcon />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-xl text-white">Generate a demo project</h3>
              <div className="mt-2">
                <p className="text-sm font-light leading-5 text-gray-400 inline">
                  Docbloc gives you the ability to generate a project with demo
                  content to help you get started and find your way around the
                  editor. To learn how to get started with demo projects,{" "}
                  <a
                    className="hover:text-white font-bold cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      bus.emit(
                        "open-help-link",
                        "guides/generate-sample-project"
                      );
                    }}
                  >
                    click here
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 h-64 bg-gray-900 mt-4 mb-4 shadow-lg rounded-md bg-opacity-50"></div>
        <div className="mt-5 sm:mt-6 flex w-full justify-center items-center">
          <a
            onClick={onComplete}
            className="mr-4 text-gray-450 font-medium text-sm cursor-pointer hover:text-red-400"
          >
            Cancel
          </a>
          <div className="w-36">
            <Button
              text="Generate project"
              loading={loading}
              disabled={loading}
              type="submit"
              buttonStyle="green"
              onClick={startGenerating}
            />
          </div>
        </div>
      </div>
    </>
  );
}
