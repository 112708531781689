import React, { useEffect } from "react";
import { TextInput, Button } from "djinn-components";
import { errorStyle } from "../../../constants/styles";
import { Controller, useForm } from "react-hook-form";
import axios from "../../../plugins/axios";
import { useNavigate } from "react-router-dom";
import { RegisterFormInputs } from "../../../pages/auth/register";

interface RegisterPasswordProps {
  loading: boolean;
  setLoading: (v: boolean) => void;
  alert: any;
  userData: {
    name: string;
    email: string;
  };
  setPasswordStrength: (v: any) => void;
  backStep: () => void;
}
export default function RegisterSetPasswordForm({
  loading,
  setLoading,
  alert,
  userData,
  setPasswordStrength,
  backStep
}: RegisterPasswordProps) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    watch
  } = useForm<RegisterFormInputs>({
    defaultValues: {
      ...userData,
      password: "",
      password_confirmation: ""
    }
  });

  const watchPassword = watch(["password", "password_confirmation"]);

  useEffect(() => {
    setPasswordStrength({
      characters: watchPassword[0].length > 7,
      uppercase: /[A-Z]/.test(watchPassword[0]),
      special: /[\W_]/.test(watchPassword[0]),
      number: /[0-9]/.test(watchPassword[0])
    });
  }, [watchPassword[0]]); // eslint-disable-line

  const onFinish = async (values: RegisterFormInputs) => {
    setLoading(true);

    try {
      await axios.post("/register", { ...values, ...userData });
      navigate(`/not-verified?email=${userData.email}`);
    } catch (err: any) {
      const resErrors = err.response.data.errors;
      if (resErrors.email)
        setError("email", { type: "api", message: resErrors.email[0] });
      if (resErrors.password)
        setError("password", {
          type: "api",
          message: resErrors.password[0]
        });
      alert.show("Failed to register", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onFinish)} className="w-full max-w-sm">
      <div className="text-gray-300 text-xs w-full text-center mb-10">
        Step <strong>2</strong> of <strong>2</strong>
      </div>

      <div className="mt-6 rounded-md shadow-sm">
        <input type="hidden" {...register("name")} />
        <input type="hidden" {...register("email")} />

        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: true
          }}
          render={({ field: { onChange, value } }) => (
            <TextInput
              type="password"
              placeholder="Password"
              value={value}
              onChange={(v) => onChange(v)}
              invalid={!!errors.password}
            />
          )}
        />
        <div className="mt-6"></div>
        <Controller
          name="password_confirmation"
          control={control}
          defaultValue=""
          rules={{
            required: true
          }}
          render={({ field: { onChange, value } }) => (
            <TextInput
              type="password"
              value={value}
              onChange={(v) => onChange(v)}
              invalid={!!errors.password}
              placeholder="Password Confirmation"
            />
          )}
        />
      </div>
      {errors.password && errors.password.type == "required" && (
        <span className={errorStyle}>Password is required</span>
      )}

      {errors.password && errors.password.type == "api" && (
        <span className={errorStyle}>{errors.password.message}</span>
      )}

      <div className="mt-8">
        <Button
          text="Create account"
          type="submit"
          buttonStyle="green"
          loading={loading}
          disabled={loading}
        />
      </div>

      <p className="mt-2 text-sm text-gray-450 p-1">
        By signing up to Docbloc you agree to our{" "}
        <a className="font-bold">Terms of Use</a> and our{" "}
        <a
          className="font-bold"
          href="https://docbloc.co/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </p>
      <div className="mt-6 text-gray-300 hover:text-white font-medium cursor-pointer">
        <a onClick={backStep}>{"<"} Back to account details</a>
      </div>
    </form>
  );
}
