import { Button, SquarePlusIcon, TextInput } from "djinn-components";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useBus } from "react-bus";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { errorStyle } from "../../../constants/styles";
import axios from "../../../plugins/axios";
import { errorParser } from "../../../utils/errorParser";
import { useProjectState } from "../../../utils/requiresProject";

interface CreateProjectVersionModalProps {
  onClose: () => void;
}

interface FormInputs {
  name: string;
}

const CreateProjectVersionModal: React.FC<CreateProjectVersionModalProps> = ({
  onClose
}) => {
  const alert = useAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const bus = useBus();
  const { project } = useProjectState();

  const getDefaults = () => {
    return {
      name: ""
    };
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError
  } = useForm<FormInputs>({
    reValidateMode: "onChange",
    defaultValues: getDefaults()
  });

  const submit = async (values: FormInputs) => {
    setLoading(true);

    try {
      const { data } = await axios.post("project-versions", {
        ...values,
        project_id: project.project.id
      });
      alert.show(`Version: ${values.name} created!`, { type: "success" });
      navigate(`/project/${data.id}`);
      onClose();
    } catch (error) {
      errorParser(error, setError);
      alert.show(`Failed to create version ${values.name}!`, { type: "error" });
    }
    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="p-5 px-12">
        <div className="mx-auto">
          <div>
            <div className="mx-auto flex items-center justify-center h-10 w-10">
              <SquarePlusIcon />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-xl text-white">Create new version</h3>
              <div className="mt-2">
                <p className="text-sm font-light leading-5 text-gray-450">
                  Enter a new version name below, this can be changed later from
                  the settings panel. Your new project version will be based off
                  the most recent one (
                  {
                    project.project.versions[
                      project.project.versions.length - 1
                    ].name
                  }
                  ), for help{" "}
                  <a
                    className="hover:text-white font-bold cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                      bus.emit(
                        "open-help-link",
                        "guides/how-to-create-new-project-version"
                      );
                    }}
                  >
                    click here
                  </a>
                </p>
              </div>
              <div className="mt-6 text-left">
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      id="name"
                      placeholder="Name"
                      value={value}
                      onChange={onChange}
                      invalid={!!errors.name}
                    />
                  )}
                />
                {errors.name && errors.name.type == "required" && (
                  <span className={errorStyle}>Project name is required</span>
                )}

                {errors.name && errors.name.type == "api" && (
                  <span className={errorStyle}>{errors.name.message}</span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <span className="flex w-full rounded-md shadow-sm">
              <Button
                text="Create version"
                loading={loading}
                disabled={loading}
                type="submit"
                buttonStyle="green"
              />
            </span>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateProjectVersionModal;
