import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../plugins/axios";
import { UserModel } from "../../types/models";
import { getAuthCookie, removeAuthCookie } from "./cookies";

interface UseAuthContext {
  user: UserModel | null | undefined;
  setUser: (user: UserModel) => void;
  loading: boolean;
  logout: () => void;
  fetchUser: () => Promise<void>;
}

export const AuthContext = createContext<UseAuthContext>({
  user: null,
  loading: true,
  setUser: () => {},
  fetchUser: async () => {},
  logout: () => {}
});

export const useAuth = () => {
  return useContext<UseAuthContext>(AuthContext);
};

function useProvideAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState<UserModel | null | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const fetchUser = useCallback(async () => {
    // setLoading(true);

    try {
      const { data } = await axios.get("me");
      await dispatch({
        type: "USER_SET_CURRENT_USER",
        currentUser: data
      });
      setUser(data);
    } catch (error) {
      setUser(null);
    }

    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (getAuthCookie()) {
      setLoading(true);
      fetchUser();
    } else {
      setUser(null);
    }
  }, [fetchUser]);

  const logout = async () => {
    setUser(null);
    dispatch({
      type: "USER_SET_CURRENT_USER",
      currentUser: null
    });
    removeAuthCookie();
    navigate("/");
  };
  return {
    user,
    setUser,
    fetchUser,
    loading,
    logout
  };
}

export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}
