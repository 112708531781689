import React, { useEffect, useState } from "react";
import { Button, UserAddIcon, TextInput } from "djinn-components";
import CreditCardInput from "../../Inputs/CreditCardInput";
import axios from "../../../plugins/axios";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Controller, useForm } from "react-hook-form";
import { errorStyle } from "../../../constants/styles";
import { useAlert } from "react-alert";
import { StripeCardElement } from "@stripe/stripe-js";

const AddPaymentMethodModal: React.FC<any> = ({ onClose, onComplete }) => {
  const alert = useAlert();
  const stripe = useStripe();
  const elements = useElements();

  const [loaded, setLoaded] = useState(false);
  const [intent, setIntent] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      name: ""
    }
  });

  const getSetupIntent = async () => {
    const { data } = await axios.get("account/payment-methods/create");
    setIntent(data.intent);
    setLoaded(true);
  };

  useEffect(() => {
    getSetupIntent();
  }, []);

  const submit = async (values: any) => {
    setLoading(true);

    try {
      if (intent == null) await getSetupIntent();
      const { error, setupIntent } = await stripe!.confirmCardSetup(
        // @ts-expect-error
        intent!.client_secret,
        {
          payment_method: {
            card: elements!.getElement(CardElement) as StripeCardElement,
            billing_details: { name: values.name }
          }
        }
      );

      if (error) return;

      await axios.post("account/payment-methods", {
        payment_method_id: setupIntent!.payment_method,
        name: values.name
      });
      alert.show("Payment method saved!", { type: "success" });
      await onComplete();
    } catch (error) {
      alert.show(
        "Failed to verify payment method details and save, please try again or contact support",
        { type: "error" }
      );
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="p-8 px-14 pt-3">
      <div className="mx-auto flex items-center justify-center h-8 w-8">
        <UserAddIcon />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <h3
          className="text-lg leading-6 font-medium text-white"
          id="modal-headline"
        >
          Add payment method
        </h3>
        <p className="leading-5 font-light text-xs text-gray-400">
          Enter details bellow to add a new payment method to your account.
        </p>
      </div>
      {!loaded && (
        <div className="text-white text-center w-auto block m-10">Loading</div>
      )}
      {loaded && (
        <>
          <div className="mb-4 w-full mt-4">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  id="name"
                  placeholder="Name"
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.name}
                />
              )}
            />
            {errors.name && errors.name.type == "required" && (
              <span className={errorStyle}>Card name is required</span>
            )}

            {errors.name && errors.name.type == "api" && (
              <span className={errorStyle}>{errors.name.message}</span>
            )}
          </div>
          <div className="mb-4 w-full">
            <CreditCardInput />
          </div>

          <div className="flex mt-10">
            <div className="mr-4">
              <Button
                text="Cancel"
                type="button"
                buttonStyle="red"
                loading={loading}
                disabled={loading}
                onClick={onClose}
              />
            </div>
            <Button
              text="Save Card"
              buttonStyle="green"
              type="submit"
              loading={loading}
              disabled={!stripe || !elements || loading}
            />
          </div>
        </>
      )}
    </form>
  );
};

export default AddPaymentMethodModal;
