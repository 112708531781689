import { ProjectVersionType } from "../types/models";

const useCheckProjectPermissions: any = (
  project: ProjectVersionType | null
) => {
  const downs: any = {
    owner: ["owner", "admin", "user"],
    admin: ["admin", "user"],
    user: ["user"]
  };

  const ups: any = {
    owner: ["owner"],
    admin: ["admin", "owner"],
    user: ["user", "admin", "owner"]
  };

  /**
   *
   * @param string role the role we are checking against
   * @param string dir the direction attribute, valid values are up, down, only
   */
  const checkRole = (role = "", dir = "up") => {
    if (!project) return false;
    const activePerm = project.project.current_user!.role;

    switch (dir) {
      case "up":
        return ups[role].includes(activePerm);
      case "down":
        return downs[role].includes(activePerm);
      case "only":
        return activePerm === role;
      default:
        return false;
    }
  };

  return checkRole;
};

export default useCheckProjectPermissions;
