import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, TickIcon, TextInput } from "djinn-components";
import { useAlert } from "react-alert";
import axios from "../../plugins/axios";
import { errorStyle } from "../../constants/styles";
import { errorParser } from "../../utils/errorParser";
import AuthSidebar from "../../components/AuthSidebar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../utils/auth/useAuth";
import { setAuthCookie } from "../../utils/auth/cookies";

interface FormInputs {
  token: string | null;
  email: string | null;
  password: string;
  password_confirmation: string;
}
const ResetPassword: React.FC = () => {
  const { fetchUser } = useAuth();
  const navigate = useNavigate();
  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const [loading, setLoading] = useState(false);

  const [passwordStrength, setPasswordStrength] = useState({
    characters: false,
    uppercase: false,
    special: false,
    number: false
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setError
  } = useForm<FormInputs>({
    shouldUnregister: false,
    defaultValues: {
      email,
      token
    }
  });

  const watchPassword = watch("password");

  useEffect(() => {
    if (watchPassword) {
      setPasswordStrength({
        characters: watchPassword.length > 7,
        uppercase: /[A-Z]/.test(watchPassword),
        special: /[\W_]/.test(watchPassword),
        number: /[0-9]/.test(watchPassword)
      });
    } else {
      setPasswordStrength({
        characters: false,
        uppercase: false,
        special: false,
        number: false
      });
    }
  }, [watchPassword]);

  const onFinish = async (values: FormInputs) => {
    setLoading(true);

    try {
      const { data } = await axios.post("/password/reset", values);
      const { accessToken } = data;

      alert.show("Your password has been reset, logging you in.", {
        type: "success"
      });

      setAuthCookie(accessToken);
      await fetchUser();
      navigate("/");
    } catch (error) {
      errorParser(error, setError);
      alert.show(
        "Password reset failed, please check details or contact support.",
        {
          type: "error"
        }
      );
    }

    setLoading(false);
  };

  return (
    <div className="flex bg-gray-800">
      <AuthSidebar title="Set new password">
        <div className="mt-6">
          <h2 className="text-md font-medium">Password must contain</h2>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              passwordStrength.characters && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="bg-green-600" />
            </span>{" "}
            At least 8 characters
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              passwordStrength.uppercase && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="bg-green-600" />
            </span>
            At least 1 upper case letter (A-Z)
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              passwordStrength.special && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="bg-green-600" />
            </span>
            At least 1 special character (e.g: !)
          </p>
          <p
            className={`text-sm font-light opacity-50 flex mt-2 ${
              passwordStrength.number && "line-through"
            }`}
          >
            <span className="w-4 h-4 block mr-2">
              <TickIcon className="bg-green-600" />
            </span>
            At least 1 number (0-9)
          </p>
        </div>
      </AuthSidebar>
      <div className="min-h-screen flex justify-between items-center w-full">
        <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 w-full">
          <form onSubmit={handleSubmit(onFinish)} className="w-full max-w-sm">
            <input type="hidden" {...register("email")} />
            <input type="hidden" {...register("token")} />

            <div className="mt-6">
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    type="password"
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.password}
                    placeholder="Password"
                  />
                )}
              />
              <div className="mt-6" />
              <Controller
                name="password_confirmation"
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    type="password"
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.password}
                    placeholder="Password Confirmation"
                  />
                )}
              />
            </div>
            {errors.password && errors.password.type === "required" && (
              <span className={errorStyle}>Password is required</span>
            )}

            {errors.password && errors.password.type === "api" && (
              <span className={errorStyle}>{errors.password.message}</span>
            )}

            {errors.token && errors.token.type === "required" && (
              <span className={errorStyle}>Token is required</span>
            )}
            <div>
              {errors.token && errors.token.type === "api" && (
                <span className={errorStyle}>{errors.token.message}</span>
              )}

              {errors.email && errors.email.type === "api" && (
                <span className={errorStyle}>{errors.email.message}</span>
              )}
            </div>

            <div className="mt-8">
              <Button
                text="Reset Password"
                type="submit"
                buttonStyle="green"
                loading={loading}
                disabled={loading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
