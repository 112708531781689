import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { errorStyle } from "../../../constants/styles";
import { RootState } from "../../../store";
import { RolesModel, UserModel } from "../../../types/models";
import { errorParser } from "../../../utils/errorParser";
import { Button, UserAddIcon } from "djinn-components";
import SelectField from "../../Inputs/SelectField";
import axios from "../../../plugins/axios";
import { useProjectState } from "../../../utils/requiresProject";
import { Link } from "react-router-dom";
import useFeatureCheck from "../../../utils/useCheckFeature";
import { getRoles } from "../../../actions/roles";

const AddUsersToProjectModal: React.FC<any> = ({ onComplete }) => {
  const dispatch = useDispatch();
  const featureCheck = useFeatureCheck();
  const { project, reloadProject } = useProjectState();

  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const users = useSelector<RootState>(
    ({ users: { list } }) => list
  ) as UserModel[];

  const roles = useSelector<RootState>(
    ({ roles: { list } }) => list
  ) as RolesModel[];

  useEffect(() => {
    dispatch<any>(getRoles());
  }, [dispatch]);

  const getDefaults = () => {
    return {
      user_id: null,
      role_id: null
    };
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: getDefaults()
  });

  const cancelAddUser = () => {
    onComplete();
  };

  const submit = async (values: any) => {
    setLoading(true);

    try {
      await axios.post(`projects/${project.id}/users`, values);
      // dispatch(getInvites());
      await reloadProject();
      onComplete();
    } catch (error) {
      errorParser(error, setError);
      alert.show("Failed to update settings!", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="p-5 px-12">
      <div>
        <div className="mx-auto flex items-center justify-center h-8 w-8">
          <UserAddIcon />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg leading-6 font-medium text-white"
            id="modal-headline"
          >
            Add user to project
          </h3>
          <p className="leading-5 font-light text-xs text-gray-400">
            Choose from the list of users to add to the project, this list
            excludes users in the project and pending invites.
          </p>
          <div className="mt-10 relative text-left">
            <Controller
              name="user_id"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SelectField
                  label="-- Choose User --"
                  selected={value}
                  onSelect={onChange}
                  invalid={!!errors.user_id}
                  emptyPlaceholder={
                    <p>
                      No users available,{" "}
                      <Link
                        to="/account/users?add=1"
                        className="text-white font-medium cursor-pointer"
                      >
                        Invite User
                      </Link>
                    </p>
                  }
                  options={users
                    .filter(
                      (selUser) =>
                        !project.project.users
                          .map((uu: UserModel) => uu.id)
                          .includes(selUser.id)
                    )
                    .map((selUser: UserModel) => ({
                      key: `${selUser.name} <${selUser.email}>`,
                      value: selUser.id
                    }))}
                />
              )}
            />
            {errors.user_id && errors.user_id.type == "required" && (
              <span className={errorStyle}>User is required</span>
            )}

            {errors.user_id && errors.user_id.type == "api" && (
              <span className={errorStyle}>{errors.user_id.message}</span>
            )}
          </div>

          <div className="mt-4 relative text-left">
            <Controller
              name="role_id"
              control={control}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <SelectField
                  label="-- Choose Role --"
                  selected={value}
                  onSelect={onChange}
                  invalid={!!errors.user_id}
                  disabled={!featureCheck("roles_and_permissions")}
                  options={roles.map((role: RolesModel) => ({
                    key: `${role.name}`,
                    value: role.id
                  }))}
                />
              )}
            />
            {!featureCheck("roles_and_permissions") && (
              <p className="text-red-500 text-xs font-medium mt-3">
                Only projects on the pro plan can assign custom user roles,{" "}
                <Link
                  to={`/project/${project.id}/pro`}
                  className="text-red-600 font-extrabold"
                >
                  {" "}
                  Upgrade now
                </Link>
              </p>
            )}
            {errors.user_id && errors.user_id.type == "required" && (
              <span className={errorStyle}>User is required</span>
            )}

            {errors.user_id && errors.user_id.type == "api" && (
              <span className={errorStyle}>{errors.user_id.message}</span>
            )}
          </div>
        </div>
      </div>

      <div className="flex mt-10">
        <div className="mr-4">
          <Button
            text="Cancel"
            type="button"
            buttonStyle="red"
            loading={loading}
            disabled={loading}
            onClick={cancelAddUser}
          />
        </div>
        <Button
          text="Add to project"
          type="submit"
          buttonStyle="green"
          loading={loading}
          disabled={loading}
        />
      </div>
    </form>
  );
};

export default AddUsersToProjectModal;
