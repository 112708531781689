import React, { useState } from "react";
import { useAlert } from "react-alert";
import useConfirmDelete from "../../../utils/withConfirmDelete";
import axios from "../../../plugins/axios";
import { Button } from "djinn-components";
import FormHolder from "../FormHolder";
import { useNavigate } from "react-router-dom";
import { useProjectState } from "../../../utils/requiresProject";

const DeleteProject: React.FC = () => {
  const alert = useAlert();
  const { project } = useProjectState();
  const navigate = useNavigate();
  const [apiLoading, setApiLoading] = useState(false);

  const deleteProject = useConfirmDelete();

  const deleteProjectAPI = async () => {
    setApiLoading(true);

    try {
      await axios.delete(`projects/${project.project.id}`);
      alert.show("Project delete successful", { type: "success" });
      navigate("/");
    } catch (error) {}
    setApiLoading(false);
  };
  return (
    <FormHolder>
      <div className=" rounded-xl shadow-sm p-10 flex flex-col justify-center items-center text-center">
        <h5 className="text-xl text-white font-medium">Delete Project</h5>
        <p className="w-2/3 mb-4 mt-2 text-gray-450 text-sm font-light">
          This will delete your project, this is reversible if you contact
          support within 6 months
        </p>
        <div className="w-32">
          <Button
            text="Delete Project"
            type="button"
            onClick={() => deleteProject("DELETE PROJECT", deleteProjectAPI)}
            buttonStyle="red"
            disabled={apiLoading}
            loading={apiLoading}
          />
        </div>
      </div>
    </FormHolder>
  );
};

export default DeleteProject;
