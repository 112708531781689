import { TrashIcon } from "djinn-components";
import React from "react";

interface FieldValue {
  title: string;
  url: string;
}

interface CustomTypeFieldProps {
  value: FieldValue;
  onChange: (v: FieldValue) => void;
  invalid?: boolean;
  onDelete: () => void;
}

const CustomTypeField: React.FC<CustomTypeFieldProps> = ({
  value,
  onChange,
  invalid = false,
  onDelete
}) => {
  return (
    <div className="flex items-center justify-center mt-4">
      <div
        className={`relative w-full rounded-3xl bg-gray-700 text-left transition ease-in-out duration-150 sm:text-sm sm:leading-5 overflow-hidden h-12 ${
          invalid ? "border-red-600" : ""
        } text-white`}
      >
        <input
          type="text"
          value={value.title}
          className="bg-gray-800 border-0 p-3 text-sm h-12 w-2/5 focus:ring-0 focus:outline-none active:outline-none pl-4"
          placeholder="Link Title"
          required
          onChange={(e) => onChange({ title: e.target.value, url: value.url })}
        />
        <input
          type="url"
          value={value.url}
          placeholder="Link URL"
          required
          className=" border-0 p-3 text-sm h-12 w-3/5 bg-transparent focus:ring-0 focus:outline-none active:outline-none placeholder:text-gray-450"
          onChange={(e) =>
            onChange({ title: value.title, url: e.target.value })
          }
        />
      </div>
      <button type="button" className="block ml-2" onClick={() => onDelete()}>
        <TrashIcon size={20} className="block text-white hover:text-red-700" />
      </button>
    </div>
  );
};

export default CustomTypeField;
