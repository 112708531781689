import axios from "../plugins/axios";

export const updateUser = (formData: any) => (dispatch: any) =>
  axios
    .put("me", formData)
    .then(({ data }) =>
      dispatch({
        type: "USER_SET_CURRENT_USER",
        currentUser: data
      })
    )
    .catch((re) => Promise.reject(re));
