import React, { useState } from "react";
import SelectField from "../../Inputs/SelectField";
import axios from "../../../plugins/axios";
import { Button, UserAddIcon, TextInput } from "djinn-components";
import { useDispatch } from "react-redux";
import { getInvites } from "../../../actions/users";
import { Controller, useForm } from "react-hook-form";
import { errorParser } from "../../../utils/errorParser";
import { errorStyle } from "../../../constants/styles";
import { useAlert } from "react-alert";

const AddUserModal = ({ onClose }: any) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  const getDefaults = () => {
    return {
      role: null,
      email: null,
      name: null
    };
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError
  } = useForm({
    reValidateMode: "onChange",
    defaultValues: getDefaults()
  });

  const cancelAddUser = () => {
    onClose();
  };

  const submit = async (values: any) => {
    setLoading(true);

    try {
      await axios.post("users", values);
      dispatch<any>(getInvites());
      onClose();
    } catch (error) {
      errorParser(error, setError);
      alert.show("Failed to send invite to user!", { type: "error" });
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="p-5 px-12">
      <div>
        <div className="mx-auto flex items-center justify-center h-8 w-8">
          <UserAddIcon />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3
            className="text-lg leading-6 font-medium text-white"
            id="modal-headline"
          >
            Add new user
          </h3>
          <p className="leading-5 font-light text-xs text-gray-400">
            Enter details bellow to invite a new user to the account.
          </p>

          <div className="mt-10 relative text-left">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  id="name"
                  placeholder="Name"
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.name}
                />
              )}
            />
            {errors.name && errors.name.type == "required" && (
              <span className={errorStyle}>User name is required</span>
            )}

            {errors.name && errors.name.type == "api" && (
              <span className={errorStyle}>{errors.name.message}</span>
            )}
          </div>

          <div className="mt-4 relative text-left">
            <Controller
              name="role"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SelectField
                  selected={value}
                  label="Role Select"
                  options={[
                    { value: "admin", key: "Admin" },
                    { value: "user", key: "User" }
                  ]}
                  onSelect={onChange}
                  invalid={!!errors.role}
                />
              )}
            />
            {errors.role && errors.role.type == "required" && (
              <span className={errorStyle}>Role is required</span>
            )}

            {errors.role && errors.role.type == "api" && (
              <span className={errorStyle}>{errors.role.message}</span>
            )}
          </div>
          <div className="mt-4 relative text-left">
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="email"
                  placeholder="Email Address"
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.email}
                />
              )}
            />

            {errors.email && errors.email.type == "required" && (
              <span className={errorStyle}>Email address is required</span>
            )}
            {errors.email && errors.email.type == "pattern" && (
              <span className={errorStyle}>
                Please enter a valid email address
              </span>
            )}
            {errors.email && errors.email.type == "api" && (
              <span className={errorStyle}>{errors.email.message}</span>
            )}
          </div>
        </div>
      </div>
      <div className="flex mt-10">
        <div className="mr-4">
          <Button
            text="Cancel"
            type="button"
            buttonStyle="red"
            loading={loading}
            disabled={loading}
            onClick={cancelAddUser}
          />
        </div>
        <Button
          text="Send Invite"
          type="submit"
          buttonStyle="green"
          loading={loading}
          disabled={loading}
        />
      </div>
    </form>
  );
};

export default AddUserModal;
