import React from "react";
import { labelStyle } from "../../../constants/styles";
import Form from "../../Form";
import FormFieldWrapper from "../../Form/FormFieldWrapper";
import { PlusIcon, TextInput } from "djinn-components";
import FormHolder from "../FormHolder";
import { useProjectState } from "../../../utils/requiresProject";
import CustomTypeField from "../../Inputs/CustomLinkField";
import { CustomLinkType } from "../../../types/models";
import FormFieldErrors from "../../Form/FormFieldErrors";

const ProjectSettingsLinks = () => {
  const { project, reloadProject } = useProjectState();

  const getDefaults = () => {
    return {
      support_url: project.project.support_url,
      links: project.project.links ?? []
    };
  };

  return (
    <FormHolder>
      <Form
        endpoint={`projects/${project.project.id}`}
        method="put"
        buttonTitle="Save Changes"
        successToast="Project settings update successful"
        getDefaults={getDefaults}
        onCompleted={async () => {
          await reloadProject();
        }}
        render={({ errors, control }) => (
          <div className="w-2/4">
            <div className="mb-4">
              <FormFieldWrapper
                id="support_url"
                label="Support URL"
                errors={errors}
                control={control}
                render={({ onChange, value, invalid }: any) => (
                  <TextInput
                    value={value}
                    placeholder="Enter support url"
                    onChange={onChange}
                    invalid={invalid}
                  />
                )}
              />

              <p className={labelStyle}>
                If given a support link will show with a link to this url. This
                must be a full valid url
              </p>
            </div>

            <div className="mb-4">
              <FormFieldWrapper
                id="links"
                label="Custom URLs"
                errors={errors}
                control={control}
                required={false}
                render={({ onChange, value }: any) => (
                  <div>
                    {value.map((link: CustomLinkType, index: number) => (
                      <div key={index}>
                        <CustomTypeField
                          value={link}
                          onDelete={() => {
                            onChange(
                              value.filter(
                                (vv: any, vi: number) => vi !== index
                              )
                            );
                          }}
                          onChange={(v) => {
                            const nv = value;
                            nv[index] = v;
                            onChange(nv);
                          }}
                        />
                        <FormFieldErrors
                          errors={errors}
                          field={`links[${index}].title`}
                          requiredMessage={"This title field is required"}
                        />
                        <FormFieldErrors
                          errors={errors}
                          field={`links[${index}].url`}
                          requiredMessage={"This url field is required"}
                        />
                      </div>
                    ))}

                    <button
                      type="button"
                      onClick={() =>
                        onChange([...value, { title: "", url: "" }])
                      }
                      className="text-white hover:text-green-600 mt-4"
                    >
                      <PlusIcon className="ml-3 w-5 h-5 block" size={20} />
                    </button>
                  </div>
                )}
              />
            </div>
          </div>
        )}
      />
    </FormHolder>
  );
};

export default ProjectSettingsLinks;
