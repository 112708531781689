import React from "react";
import { useRoutes } from "react-router-dom";
import store from "./store";
import { Provider as ReduxProvider } from "react-redux";
import { authenticatedRoutes } from "./routes";
import { CookiesProvider } from "react-cookie";
import { Provider as BusProvider } from "react-bus";
import { DjinnComponentsProvider, HelpAttachment } from "djinn-components";
import { requiresAuth } from "./utils/auth/requiresAuth";
import { ProvideAuth } from "./utils/auth/useAuth";

const AppContainer: React.FC = () => {
  const routerElement = useRoutes(authenticatedRoutes);
  return <>{routerElement}</>;
};

const AppObj = requiresAuth(AppContainer);

function App() {
  return (
    <ReduxProvider store={store}>
      <BusProvider>
        <CookiesProvider>
          <ProvideAuth>
            <DjinnComponentsProvider>
              <AppObj />
              <HelpAttachment
                appPath="docbloc"
                appName="Docbloc"
                appToken={process.env.REACT_APP_DJINN_ADMIN_API_KEY as string}
                logoPath="/icon.svg"
              />
            </DjinnComponentsProvider>
          </ProvideAuth>
        </CookiesProvider>
      </BusProvider>
    </ReduxProvider>
  );
}

export default App;
