import React from "react";
import { HiOutlineDocument } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
import ContextMenuItem from "../../ContextMenu/ContextMenuItem";

import { BsThreeDotsVertical } from "react-icons/bs";
import useConfirmDelete from "../../../utils/withConfirmDelete";
import axios from "../../../plugins/axios";
import { DuplicateIcon, PencilIcon, TrashIcon } from "djinn-components";

const MenuItemContentPage = ({ item, activeString, onReload }: any) => {
  const params = useParams<{ project: string }>();
  const navigate = useNavigate();
  const deletePage = useConfirmDelete();

  const getItemLink = () => {
    return `/project/${params.project}/edit/page/${item.id}`;
  };

  const deletePageCallback = async () => {
    try {
      await axios.delete(`pages/${item.id}`);
      await onReload();
      navigate(`/project/${params.project}/edit`);
    } catch (error) {}
  };

  const pageDuplicateAction = async () => {
    try {
      const { data } = await axios.post(`pages/${item.id}/duplicate`);

      await onReload();
      navigate(`/project/${params.project}/edit/page/${data.id}`);
    } catch (error) {}
  };
  return (
    <a
      href={getItemLink()}
      onClick={(e) => {
        e.preventDefault();
        navigate(getItemLink());
      }}
      className={`group pl-4 pr-0 link flex justify-between items-center text-sm text-white font-bold leading-none mb-1 rounded overflow-hidden ${
        activeString == `page-${item.id}` ? "bg-gray-1000" : "bg-gray-800"
      }`}
    >
      <div className="w-full flex justify-start items-center">
        <HiOutlineDocument size={20} className="mr-3 text-gray-400" />
        {item.name}
      </div>
      {/* @ts-ignore */}

      <ContextMenuTrigger id={`page-context-${item.id}`} mouseButton={0}>
        <span className="w-6 h-14 flex items-center justify-center cursor-pointer text-gray-500 hover:text-white opacity-0 group-hover:opacity-100 transition ease-in-out duration-150">
          <BsThreeDotsVertical size={18} />
        </span>
      </ContextMenuTrigger>
      {/* @ts-ignore */}

      <ContextMenu
        id={`page-context-${item.id}`}
        className="bg-gray-950 py-2 rounded-md shadow-lg w-40 overflow-hidden"
      >
        <ContextMenuItem
          onClick={() => {
            navigate(getItemLink());
          }}
          Icon={PencilIcon}
        >
          Edit page
        </ContextMenuItem>
        <ContextMenuItem onClick={pageDuplicateAction} Icon={DuplicateIcon}>
          Duplicate
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() => deletePage("DELETE PAGE", deletePageCallback)}
          Icon={TrashIcon}
        >
          Move to trash
        </ContextMenuItem>
      </ContextMenu>
    </a>
  );
};

export default MenuItemContentPage;
