import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import AuthSidebar from "../../components/AuthSidebar";
import RegisterDetailsSidebar from "../../components/Register/Sidebars/RegisterDetails";
import RegisterDetailsForm from "../../components/Register/Forms/RegisterDetails";
import RegisterPasswordSidebar from "../../components/Register/Sidebars/SetPassword";
import RegisterSetPasswordForm from "../../components/Register/Forms/SetPassword";

export interface RegisterFormInputs {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

const RegisterScreen: React.FC<any> = () => {
  const alert = useAlert();

  const [step, setStep] = useState("details");

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);

  const [passwordStrength, setPasswordStrength] = useState({
    characters: false,
    uppercase: false,
    special: false,
    number: false
  });

  const validateDetails = (data: any) => {
    setUserData(data);
    setStep("password");
  };

  return (
    <div className="flex bg-gray-800">
      <AuthSidebar title="Create a new account">
        <div>
          <Link
            to="/"
            className="text-gray-500 cursor-pointer transition ease-in-out duration-150 mr-3"
          >
            Sign in
          </Link>
          <Link
            to="/register"
            className="text-white border-b py-1 border-green-600 cursor-pointer transition ease-in-out duration-150"
          >
            Create account
          </Link>
        </div>
      </AuthSidebar>
      <div className="min-h-screen flex justify-between items-center w-full">
        {step === "details" && <RegisterDetailsSidebar />}
        {step === "password" && (
          <RegisterPasswordSidebar passwordStrength={passwordStrength} />
        )}

        <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 w-full">
          {step === "details" && (
            <RegisterDetailsForm
              loading={loading}
              userData={userData}
              setLoading={setLoading}
              alert={alert}
              validateAndNext={validateDetails}
            />
          )}
          {step === "password" && (
            <RegisterSetPasswordForm
              loading={loading}
              setLoading={setLoading}
              alert={alert}
              userData={userData!}
              setPasswordStrength={setPasswordStrength}
              backStep={() => setStep("details")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
