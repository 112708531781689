import React from "react";
import { SearchIcon } from "djinn-components";

export default function SearchInput({
  value,
  onChange,
  placeholder = "Enter text",
  id = "value",
  disabled = false
}: any) {
  return (
    <label
      className="form-input px-3 bg-gray-250 bg-opacity-50 w-full border-none focus-within:ring-white text-gray-300 focus-within:text-white rounded-3xl shadow-sm ring-1 ring-gray-250 ring-opacity-25 focus-within:ring-opacity-100 transition duration-500 ease-in-out  disabled:opacity-75 ring-transparent flex items-center justify-between"
      htmlFor={id}
    >
      <input
        id={id}
        name={id}
        type="text"
        value={value ?? ""}
        onChange={onChange}
        disabled={disabled}
        className="form-input ring-0 bg-gray-250 bg-opacity-0 text-sm py-2 border-none placeholder-gray-450 sm:leading-5 w-full focus:outline-none focus:border-none focus:ring-0"
        placeholder={placeholder}
        autoComplete="off"
      />
      <SearchIcon className="block w-4 h-4" />
    </label>
  );
}
