// @ts-nocheck
export const getItemsL = (items) => {
  try {
    return Object.values(items);
  } catch (error) {
    return [];
  }
};

export const getChildItemType = (item) => {
  switch (item.content_model) {
    case "ProjectEndpoint":
      return "endpoint";
    case "ProjectGroup":
      return "group";
    case "ProjectContentPage":
      return "page";
    default:
      return "None";
  }
};

export const arrayMove = (arr, oldIndex, newIndex) => {
  while (oldIndex < 0) {
    oldIndex += arr.length;
  }
  while (newIndex < 0) {
    newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const remapItem = (item, itmInd, oldIndex, newIndex, groupId) => {
  const nI = {
    ...item
  };

  let nItems = getItemsL(item.items);

  if (item.content_model === "ProjectGroup") {
    if (item.id === groupId) {
      nItems = arrayMove(nItems, oldIndex, newIndex);
    }

    nItems = nItems.map((itemI, itmIInd) =>
      remapItem(itemI, itmIInd, oldIndex, newIndex)
    );

    nI.items = nItems;
  }

  return nI;
};

export const idIndexArr = (item, index) => {
  const re = {
    id: item.id,
    type: item.content_model,
    order: index + 1
  };

  if (item.content_model === "ProjectGroup") {
    re.items = getItemsL(item.items).map(idIndexArr);
  }

  return re;
};

export const groupFilter = (group, search) => {
  return (
    group.name.toLowerCase().includes(search.toLowerCase()) ||
    group.items.filter((item) => itemFilter(item, search)).length > 0 // eslint-disable-line
  );
};

const endpointFilter = (endpoint, search) => {
  return (
    endpoint.name.toLowerCase().includes(search.toLowerCase()) ||
    endpoint.endpoint.toLowerCase().includes(search.toLowerCase()) ||
    (endpoint.content &&
      endpoint.content.toLowerCase().includes(search.toLowerCase())) ||
    (endpoint.request &&
      JSON.stringify(endpoint.request)
        .toLowerCase()
        .includes(search.toLowerCase())) ||
    (endpoint.response &&
      JSON.stringify(endpoint.response)
        .toLowerCase()
        .includes(search.toLowerCase())) ||
    (endpoint.parameters &&
      JSON.stringify(endpoint.parameters)
        .toLowerCase()
        .includes(search.toLowerCase())) ||
    endpoint.method.toLowerCase().includes(search.toLowerCase())
  );
};

export const pageFilter = (page, search) => {
  return (
    page.name.toLowerCase().includes(search.toLowerCase()) ||
    (page.content && page.content.toLowerCase().includes(search.toLowerCase()))
  );
};

export const itemFilter = (item, search) => {
  if (!search || search == null) return true;
  switch (getChildItemType(item)) {
    case "page":
      return pageFilter(item, search);
    case "endpoint":
      return endpointFilter(item, search);
    case "group":
      return groupFilter(item, search);
    default:
      return false;
  }
};

export const getItemStyle = (isDragging, isDropping, draggableStyle, type) => ({
  ...draggableStyle,
  padding: isDropping && type == "group" ? "2px" : "0",
  background: isDropping && type == "group" ? "green" : "transparent"
});

export const getListStyle = (isDraggingOver, draggableStyle? = {}) => ({
  ...draggableStyle,
  background: isDraggingOver ? "rgba(255,255,255,0.1)" : "transparent",
  padding: isDraggingOver ? 4 : 0,
  paddingVertical: 40,
  height: "100%"
});
