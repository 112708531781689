import React from "react";
import Login from "./pages/auth/index";
import ProjectIndexScreen from "./pages/project";
import ForgotPassword from "./pages/auth/forgot-password";
import RegisterScreen from "./pages/auth/register";
import ResetPassword from "./pages/auth/reset-password";
import JoinPage from "./pages/join";
import JoinTeamPage from "./pages/join-team";
import VerifyEmailScreen from "./pages/auth/verify-email";
import NotVerifiedScreen from "./pages/auth/not-verified";
import ChooseProjectScreen from "./pages/choose-project";
import SystemMaintenance from "./pages/system-maintenance";
import ImportPage from "./pages/import";
import CreateTeamPage from "./pages/create-team";
import SuccessScreen from "./pages/success";
import ErrorScreen from "./pages/error";
import ManageAccountPage from "./pages/account";
import EditProfilePage from "./pages/profile";
import { Navigate } from "react-router-dom";

const sharedRoutes = [
  {
    path: "/system-maintenance",
    element: <SystemMaintenance />
  },
  {
    path: "/s/:type",
    element: <SuccessScreen />,
    private: false,
    guestOnly: false
  },
  {
    path: "/e/:type",
    element: <ErrorScreen />
  }
];
export const authenticatedRoutes = [
  ...sharedRoutes,
  {
    path: "/",
    element: <ChooseProjectScreen />
  },

  {
    path: "/choose-project",
    element: <ChooseProjectScreen />
  },

  {
    path: "/create-team",
    element: <CreateTeamPage />
  },
  {
    path: "/project/:project/*",
    element: <ProjectIndexScreen />
  },
  {
    path: "/account/*",
    element: <ManageAccountPage />
  },
  {
    path: "/profile",
    element: <EditProfilePage />
  },
  {
    path: "/import/:type",
    element: <ImportPage />
  },
  {
    path: "/*",
    element: <Navigate replace to="/" />
  }
];

export const unauthenticatedRoutes = [
  ...sharedRoutes,
  {
    path: "/",
    element: <Login />
  },
  {
    path: "/join",
    element: <JoinPage />
  },
  {
    path: "/join-team",
    element: <JoinTeamPage />
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />
  },
  {
    path: "/reset-password",
    element: <ResetPassword />
  },
  {
    path: "/register",
    element: <RegisterScreen />
  },
  {
    path: "/not-verified",
    element: <NotVerifiedScreen />
  },
  {
    path: "/verify-email",
    element: <VerifyEmailScreen />
  },
  {
    path: "/*",
    element: <Navigate replace to="/" />
  }
];
