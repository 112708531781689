import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, TextInput } from "djinn-components";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import axios from "../../plugins/axios";
import AuthSidebar from "../../components/AuthSidebar";
import { errorStyle } from "../../constants/styles";

function ForgotPassword() {
  const alert = useAlert();

  const {
    setError,
    handleSubmit,
    formState: { errors },
    control
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {
    setLoading(true);
    return axios
      .post("/password/email", values)
      .then(() => {
        alert.show(
          "An email has been sent with instructions on how to reset your password.",
          { type: "success" }
        );
      })
      .catch(
        ({
          response: {
            data: { errors: rsErrors }
          }
        }) => {
          if (rsErrors.email)
            setError("email", { type: "api", message: rsErrors.email[0] });
          alert.show("Failed, please check email address", { type: "error" });
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex bg-gray-800">
      <AuthSidebar title="Forgot password">
        <div>
          <p className="text-sm text-gray-500">
            Enter your details to reset your password
          </p>
        </div>
      </AuthSidebar>

      <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 w-full">
        <form onSubmit={handleSubmit(onFinish)} className="w-full max-w-sm">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="email"
                placeholder="Email address"
                value={value}
                onChange={onChange}
                invalid={!!errors.email}
              />
            )}
          />
          {errors.email && errors.email.type == "required" && (
            <span className={errorStyle}>Email address is required</span>
          )}
          {errors.email && errors.email.type == "pattern" && (
            <span className={errorStyle}>
              Please enter a valid email address
            </span>
          )}
          {errors.email && errors.email.type == "api" && (
            <span className={errorStyle}>
              {errors.email.message?.toString()}
            </span>
          )}

          <div className="mt-5 text">
            <Button
              type="submit"
              onClick={() => {}}
              loading={loading}
              disabled={loading}
              buttonStyle="green"
              text="Reset Password"
            />
          </div>
          <div className="mt-4 text-center">
            <Link
              to="/"
              className="text-gray-500 hover:text-gray-400 text-sm cursor-pointer transition ease-in-out duration-150"
            >
              Cancel, back to <strong>Sign in</strong>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
