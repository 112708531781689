import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const useCheckPermissions = () => {
  const user = useSelector(
    ({ user: { currentUser } }: RootState) => currentUser
  );

  /**
   *
   * @param string role the role we are checking against
   * @param string dir the direction attribute, valid values are up, down, only
   */
  const checkRole: (role: string, dir: "up" | "down" | "only") => boolean =
    useCallback(
      (role = "", dir = "up") => {
        const activePerm = user.role;

        const downs: any = {
          owner: ["owner", "admin", "user"],
          admin: ["admin", "user"],
          user: ["user"]
        };

        const ups: any = {
          owner: ["owner"],
          admin: ["admin", "owner"],
          user: ["user", "admin", "owner"]
        };

        switch (dir) {
          case "up":
            return ups[role].includes(activePerm);
          case "down":
            return downs[role].includes(activePerm);
          case "only":
            return activePerm === role;
          default:
            return false;
        }
      },
      [user]
    );

  return checkRole;
};

export default useCheckPermissions;
