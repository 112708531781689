import { PlusIcon, CogsFilledIcon, Modal } from "djinn-components";
import React, { useState } from "react";
import { RolesModel } from "../../types/models";
import { strFromSlug } from "../../utils/strings.utils";
import useCheckPermissions from "../../utils/useCheckPermission";
import { withApiResource } from "../../utils/withApiResource";
import HelpLink from "../HelpLink";
import CreateRoleModal from "../Modals/Roles/Create";
import UpdateRoleModal from "../Modals/Roles/Update";
interface RolesAndPermissionsProps {
  data: RolesModel[];
  reload: () => void;
}
const RolesAndPermissions: React.FC<RolesAndPermissionsProps> = ({
  data,
  reload
}) => {
  const [showAddRole, setShowAddRole] = useState(false);
  const [editRole, setEditRole] = useState<RolesModel | undefined>(undefined);

  const permission = useCheckPermissions();

  const displayPermissionsList = (list: string[]) => {
    return list
      .slice(0, 2)
      .map((li) => {
        return strFromSlug(li.replace("account.", "").replace("project.", ""));
      })
      .join(", ");
  };

  return (
    <main className="flex-1 relative z-0 overflow-y-auto px-8">
      <div className="max-w-5xl mx-auto">
        <div className="w-full">
          <table
            className="table-auto border-separate mr-0 w-full"
            style={{ borderSpacing: "0 1.2rem" }}
          >
            <thead className="opacity-100 bg-opacity-100">
              <tr>
                <td className="bg-gray-800 text-left text-md leading-4 font-large text-white tracking-wider opacity-100 bg-opacity-100 pl-6">
                  Name
                </td>
                <td className="bg-gray-800 text-left text-md leading-4 font-large text-white tracking-wider">
                  Permissions
                </td>
                <td className="bg-gray-800 text-left text-md leading-4 font-large text-white tracking-wider">
                  Users
                </td>
                <td className="px-6 bg-gray-800 lg:w-10 w-12" width="5%"></td>
              </tr>
            </thead>
            <tbody className="max-h-124 w-full">
              {data.map((role: any, index: number) => (
                <tr
                  className={
                    "bg-gray-600 bg-opacity-50 rounded-full mb-6 h-12 pl-6 overflow-hidden w-full"
                  }
                  key={index}
                >
                  <td className="whitespace-no-wrap rounded-l-full text-sm leading-5 font-regular text-white opacity-75 pl-6">
                    {role.name}
                  </td>
                  <td className="whitespace-no-wrap text-sm font-light leading-5 text-white opacity-50 ">
                    {displayPermissionsList(role.permissions)}{" "}
                    {role.permissions.length > 2 && (
                      <span className="text-green-600 font-medium opacity-100">
                        + {role.permissions.length - 2} More
                      </span>
                    )}
                  </td>
                  <td
                    className="whitespace-no-wrap  text-sm font-light leading-5 text-white opacity-50 "
                    width="30%"
                  >
                    -
                  </td>
                  <td width="5%" className="rounded-r-full">
                    {permission("admin", "down") ? (
                      <div className="block w-5 h-5 mr-2 lg:mr-0"></div>
                    ) : (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          setEditRole(role);
                          // setShowUserOptions(user);
                        }}
                        className="block h-5 w-5 cursor-pointer text-gray-400 hover:text-white"
                      >
                        <CogsFilledIcon
                          className="block h-5 w-5 cursor-pointer text-gray-400 hover:text-white"
                          size={20}
                        />
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-full flex justify-between items-center mt-4">
          <HelpLink
            text="Need help managing team roles?"
            link="guides/account-roles-and-permissions"
          />
          <button
            className="h-10 mb-1 bg-green-600 hover:bg-green-700 rounded-full px-4 flex justify-center items-center text-white text-xs font-medium focus:outline-none"
            onClick={(e) => {
              e.preventDefault();
              setShowAddRole(true);
            }}
          >
            Add Role <PlusIcon className="ml-3 w-5 h-5" size={20} />
          </button>
        </div>
      </div>
      <Modal
        shown={showAddRole}
        dismissible
        onClose={() => {
          setShowAddRole(false);
        }}
      >
        <CreateRoleModal
          onClose={() => {
            // getUsers();
            reload();
            setShowAddRole(false);
          }}
        />
      </Modal>

      <Modal
        shown={editRole != undefined}
        dismissible
        onClose={() => {
          setEditRole(undefined);
        }}
      >
        <UpdateRoleModal
          role={editRole}
          onClose={() => {
            reload();
            setEditRole(undefined);
          }}
        />
      </Modal>
    </main>
  );
};

export default withApiResource(RolesAndPermissions, "/roles");
