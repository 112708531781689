import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./styles/index.css";
import "jsoneditor-react/es/editor.min.css";
import * as Sentry from "@sentry/react";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import AlertTemplate from "react-alert-template-basic";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      // new Sentry.BrowserTracing({
      //   tracePropagationTargets: [
      //     "localhost",
      //     /^https:\/\/api\.rd-dev\.djinndigital\.com/,
      //   ],
      // }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.3,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE
};

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Router>
    <AlertProvider template={AlertTemplate} {...options}>
      <App />
    </AlertProvider>
  </Router>
);

reportWebVitals();
