import React from "react";
import { ContextMenu, ContextMenuTrigger } from "react-contextmenu";
import ContextMenuItem from "../../ContextMenu/ContextMenuItem";

import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

import useConfirmDelete from "../../../utils/withConfirmDelete";
import axios from "../../../plugins/axios";
import { TrashIcon, PencilIcon, DuplicateIcon } from "djinn-components";

const MenuItemEndpoint = ({ item, activeString, onReload }: any) => {
  const params = useParams<{ project: string }>();
  const navigate = useNavigate();

  const getClass = (method: string) => {
    switch (method) {
      case "DELETE":
        return "text-red-700";
      case "POST":
        return "text-indigo-400";
      case "PUT":
        return "text-orange-500";
      case "GET":
      default:
        return "text-green-500";
    }
  };

  const getShortendMethod = () => {
    if (item.method == "DELETE") return "DEL";
    if (item.method == "PATCH") return "PAT";
    if (item.method == "OPTIONS") return "OPT";
    return item.method;
  };

  const getItemLink = () => {
    return `/project/${params.project}/edit/endpoint/${item.id}`;
  };

  const deleteEndpoint = useConfirmDelete();

  const deleteEndpointCallback = async () => {
    try {
      await axios.delete(`endpoints/${item.id}`);
      await onReload();
      navigate(`/project/${params.project}/edit`);
    } catch (error) {}
  };

  const endpointDuplicateAction = async () => {
    try {
      const { data } = await axios.post(`endpoints/${item.id}/duplicate`);

      await onReload();
      navigate(`/project/${params.project}/edit/endpoint/${data.id}`);
    } catch (error) {}
  };

  return (
    <a
      href={getItemLink()}
      onClick={(e) => {
        e.preventDefault();
        navigate(getItemLink());
      }}
      className={`group link flex justify-start items-center text-sm text-white font-regular leading-5 mb-1 rounded overflow-hidden ${
        activeString == `endpoint-${item.id}` ? "bg-gray-1000" : "bg-gray-800"
      }`}
    >
      <span
        className={`w-2/12 h-14 flex justify-center items-center font-bold px-2 text-xs ${getClass(
          item.method
        )}`}
      >
        {getShortendMethod()}
      </span>
      <div className="h-14 pt-2 pb-1 w-9/12 flex flex-col items-start justify-center">
        <span className="block text-sm font-bold leading-none">
          {item.name}
        </span>
        <span
          className="block text-xs text-gray-450 truncate"
          style={{ lineHeight: "1.2" }}
        >
          {item.endpoint}
        </span>
      </div>
      {/* @ts-ignore */}
      <ContextMenuTrigger id={`endpoint-context-${item.id}`} mouseButton={0}>
        <div className="w-6 h-14 flex items-center justify-center cursor-pointer text-gray-500 hover:text-white  opacity-0 group-hover:opacity-100 transition ease-in-out duration-15">
          <BsThreeDotsVertical size={18} />
        </div>
      </ContextMenuTrigger>
      {/* @ts-ignore */}
      <ContextMenu
        id={`endpoint-context-${item.id}`}
        className="bg-gray-950 py-2 rounded-md shadow-lg w-40 overflow-hidden"
      >
        <ContextMenuItem
          onClick={() => {
            navigate(getItemLink());
          }}
          Icon={PencilIcon}
        >
          Edit endpoint
        </ContextMenuItem>
        <ContextMenuItem onClick={endpointDuplicateAction} Icon={DuplicateIcon}>
          Duplicate
        </ContextMenuItem>
        <ContextMenuItem
          onClick={() =>
            deleteEndpoint("DELETE ENDPOINT", deleteEndpointCallback)
          }
          Icon={TrashIcon}
        >
          Move to trash
        </ContextMenuItem>
      </ContextMenu>
    </a>
  );
};

export default MenuItemEndpoint;
