import axios from "../../plugins/axios";
import React, { useCallback, useEffect, useState } from "react";
import HelpLink from "../HelpLink";
import AnalyticsBlock from "../AnalyticsBloc";
import { useProjectState } from "../../utils/requiresProject";

type ViewType = "7_days" | "30_days" | "6_months";
const ProjectDashboardAnalytics: React.FC = () => {
  const { project } = useProjectState();
  const [data, setData] = useState<any>(null);
  const [view, setView] = useState<ViewType>("30_days");
  const [showChangeView, setShowChangeView] = useState(false);

  const views = {
    "7_days": "Past 7 days",
    "30_days": "Past 30 days",
    "6_months": "Past 6 months"
  };

  const getTotalViewers = (period = "this") => {
    try {
      switch (period) {
        case "last":
          switch (view) {
            case "7_days":
              return data.total_viewers_week_last;
            case "30_days":
              return data.total_viewers_month_last;
            default:
              return data.total_viewers_6_month_last;
          }
        default:
          switch (view) {
            case "7_days":
              return data.total_viewers_week;
            case "30_days":
              return data.total_viewers_month;
            default:
              return data.total_viewers_6_month;
          }
      }
    } catch (error) {
      return "...";
    }
  };

  const getEngagement = (period = "this") => {
    try {
      switch (period) {
        case "last":
          switch (view) {
            case "7_days":
              return data.avg_engagement_week_last;
            case "30_days":
              return data.avg_engagement_month_last;
            default:
              return data.avg_engagement_6_month_last;
          }
        default:
          switch (view) {
            case "7_days":
              return data.avg_engagement_week;
            case "30_days":
              return data.avg_engagement_month;
            default:
              return data.avg_engagement_6_month;
          }
      }
    } catch (error) {
      return "...";
    }
  };

  const getNewViewers = (period = "this") => {
    try {
      switch (period) {
        case "last":
          switch (view) {
            case "7_days":
              return data.new_viewers_week_last;
            case "30_days":
              return data.new_viewers_month_last;
            default:
              return data.new_viewers_6_month_last;
          }
        default:
          switch (view) {
            case "7_days":
              return data.new_viewers_week;
            case "30_days":
              return data.new_viewers_month;
            default:
              return data.new_viewers_6_month;
          }
      }
    } catch (error) {
      return "...";
    }
  };

  const getAnalytics = useCallback(async () => {
    const res = await axios.get(`analytics/${project.id}`);
    setData(res.data);
  }, [project]);

  useEffect(() => {
    getAnalytics();
  }, [getAnalytics]);
  return (
    <div className="col-span-2 bg-gray-600 bg-opacity-50 h-full w-full rounded-xl p-8 flex justify-start items-start flex-col overflow-auto">
      <div className="flex justify-between items-center w-full">
        <h5 className="text-white font-medium text-lg">Analytics</h5>
        <div className="relative">
          <a
            className="hover:text-gray-300 cursor-pointer text-xs text-gray-450 flex items-center"
            onClick={() => setShowChangeView(!showChangeView)}
          >
            {views[view]}{" "}
            <span className="w-3 h-3 ml-2">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 8 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M7.91815 0.0772947C7.80788 -0.0257649 7.62871 -0.0257649 7.51845 0.0772947L4.00387 3.36876L0.482394 0.0772947C0.372132 -0.0257649 0.192957 -0.0257649 0.082696 0.0772947C-0.0275653 0.180354 -0.0275653 0.347826 0.082696 0.450886L3.79713 3.92271C3.85226 3.97423 3.92117 4 3.99698 4C4.06589 4 4.14169 3.97423 4.19682 3.92271L7.91125 0.450886C8.02841 0.347826 8.02841 0.180354 7.91815 0.0772947Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </a>
          {showChangeView && (
            <div className="absolute bg-gray-800 shadow-sm w-48 right-0 top-5 rounded-md overflow-hidden">
              {Object.entries(views).map(([va, ke]: any) => (
                <div
                  key={va}
                  className="px-3 py-3 text-sm font-medium hover:bg-black cursor-pointer"
                  onClick={() => {
                    setShowChangeView(false);
                    setView(va);
                  }}
                >
                  {ke}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <AnalyticsBlock
        title="Total Viewers"
        value={getTotalViewers()}
        from={getTotalViewers("last")}
      />
      <AnalyticsBlock
        title="Avg Engagement (mins)"
        value={parseFloat(getEngagement()).toFixed(1)}
        from={parseFloat(getEngagement("last")).toFixed(1)}
      />
      <AnalyticsBlock
        title="New Viewers"
        value={getNewViewers()}
        from={getNewViewers("last")}
      />
      <div className="w-full pt-6">
        <HelpLink
          text="Need help with analytics?"
          link="guides/project-analytics"
        />
      </div>
    </div>
  );
};

export default ProjectDashboardAnalytics;
