import React from "react";
import { Droppable } from "react-beautiful-dnd";
import ACPGhostWidget from ".";
import ACPAddItem from "../AddItem";
import ACPFieldEditorHolder from "../FieldHolder";
import {
  ACPColumnWidget,
  ACPFieldComponentProps,
  newItemByType
} from "../utils";

const getListStyle = (isDraggingOver: boolean, draggableStyle = {}) => ({
  ...draggableStyle
});

const ACPColumnEditorWidget: React.FC<
ACPFieldComponentProps<ACPColumnWidget>
> = ({ value, onChange, index, onDelete }) => {
  return (
    <ACPFieldEditorHolder deleteClick={onDelete} index={index} dndId={value.id}>
      <h4 className="font-bold text-md mx-auto mt-4 text-center">Column</h4>

      <Droppable
        key={`${value.type}-${value.id}`}
        droppableId={`${value.type}-${value.id}`}
        isCombineEnabled
        type="widgets"
      >
        {(droppableProvided, droppableSnapshot) => (
          <div
            className="p-2"
            ref={droppableProvided.innerRef}
            style={getListStyle(droppableSnapshot.isDraggingOver)}
            {...droppableProvided.droppableProps}
          >
            {value.children.map((widget, index2) => (
              <ACPGhostWidget
                key={index2}
                index={index2}
                value={widget}
                onChange={(changedVal) => {
                  const nv = value;
                  nv.children[index2] = changedVal;
                  onChange(nv);
                }}
                onDelete={() => {
                  onChange({
                    ...value,
                    children: value.children.filter(
                      (child) => child.id !== widget.id
                    )
                  });
                }}
              />
            ))}
            {droppableProvided.placeholder}
            <ACPAddItem
              onComplete={(t) => {
                onChange({
                  ...value,
                  children: [...value.children, newItemByType(t)]
                });
              }}
              disabled={["column", "row"]}
            />
          </div>
        )}
      </Droppable>
    </ACPFieldEditorHolder>
  );
};

export default ACPColumnEditorWidget;
