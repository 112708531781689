import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { Button, TextInput, CheckboxInput } from "djinn-components";
import { useAlert } from "react-alert";
import { useCookies } from "react-cookie";
import { AiFillGithub, AiOutlineGoogle } from "react-icons/ai";
import { errorStyle } from "../../constants/styles";
import AuthSidebar from "../../components/AuthSidebar";
import axios from "../../plugins/axios";
import CryptoJS from "crypto-js";
import { useAuth } from "../../utils/auth/useAuth";
import { removeAuthCookie, setAuthCookie } from "../../utils/auth/cookies";

interface FormInputs {
  email: string;
  password: string;
  remember: boolean;
}
const Login: React.FC<any> = () => {
  const { fetchUser } = useAuth();
  const alert = useAlert();
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get("authToken");

  if (authToken) {
    setAuthCookie(authToken);
    fetchUser();
  }

  const [cookies, setCookie, removeCookie] = useCookies([
    "remember-token",
    "token"
  ]);

  const savedData = cookies["remember-token"]
    ? JSON.parse(
      CryptoJS.AES.decrypt(
        cookies["remember-token"],
        "phoenixNDjinn"
      ).toString(CryptoJS.enc.Utf8)
    )
    : {};

  const {
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm<FormInputs>({
    defaultValues: savedData
  });

  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    removeAuthCookie();
    setLoading(true);

    try {
      const {
        data: { accessToken }
      } = await axios.post("/login", values);

      removeCookie("remember-token");

      if (values.remember === true) {
        setCookie(
          "remember-token",
          CryptoJS.AES.encrypt(
            JSON.stringify(values),
            "phoenixNDjinn"
          ).toString()
        );
      }

      setAuthCookie(accessToken);
      fetchUser();
    } catch (e: any) {
      const {
        response: {
          data: { errors: fErrors }
        }
      } = e;

      if (fErrors.email)
        setError("email", { type: "api", message: fErrors.email[0] });
      if (fErrors.password)
        setError("password", { type: "api", message: fErrors.password[0] });
      alert.show("Incorrect Login details", { type: "error" });
    }

    setLoading(false);
  };

  const getSocialLoginRoute = (provider: "google" | "github") =>
    `${process.env.REACT_APP_API_URL}auth/social/${provider}`;

  return (
    <div className="flex bg-gray-800">
      <AuthSidebar title="Sign in to your account">
        <div>
          <Link
            to="/"
            className="text-white border-b py-1 border-green-600 mr-3 cursor-pointer transition ease-in-out duration-150"
          >
            Sign in
          </Link>
          <Link
            to="/register"
            className="text-gray-500  cursor-pointer transition ease-in-out duration-150"
          >
            Create account
          </Link>
        </div>
      </AuthSidebar>
      <div className="min-h-screen flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8 w-full">
        <form onSubmit={handleSubmit(onFinish)} className="w-full max-w-sm">
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            }}
            render={({ field: { onChange, value } }) => (
              <TextInput
                type="email"
                placeholder="Email address"
                value={value}
                onChange={onChange}
                invalid={!!errors.email}
              />
            )}
          />

          {errors.email && errors.email.type == "required" && (
            <span className={errorStyle}>Email address is required</span>
          )}
          {errors.email && errors.email.type == "pattern" && (
            <span className={errorStyle}>
              Please enter a valid email address
            </span>
          )}

          {errors.email && errors.email.type == "api" && (
            <span className={errorStyle}>{errors.email.message}</span>
          )}

          <div className="mt-4 rounded-md shadow-sm">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  type="password"
                  placeholder="Password"
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.password}
                />
              )}
            />
          </div>
          {errors.password && errors.password.type == "required" && (
            <span className={errorStyle}>Password is required</span>
          )}
          {errors.password && errors.password.type == "api" && (
            <span className={errorStyle}>{errors.password.message}</span>
          )}

          <div className="mt-6 flex items-center justify-between">
            <Controller
              name="remember"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Remember me"
                  id="remember"
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <div className="text-xs leading-5">
              <Link
                to="/forgot-password"
                className="text-gray-450 hover:text-gray-300 focus:outline-none focus:underline transition ease-in-out duration-150"
              >
                Forgot your password?
              </Link>
            </div>
          </div>

          <div className="mt-6">
            <Button
              text="Sign in"
              type="submit"
              buttonStyle="green"
              loading={loading}
              disabled={loading}
            />
          </div>

          <div className="flex flex-col items-center justify-center mt-10 border-t border-gray-600 pt-10">
            <span className="font-light text-sm text-gray-400">
              Or, sign in with
            </span>
            <div className="grid gap-4 grid-cols-2 mt-4">
              <a
                href={getSocialLoginRoute("github")}
                className="rounded-full py-3 px-2 bg-white text-black flex items-center justify-center font-bold text-sm w-36 hover:bg-black hover:text-white transition ease-in-out duration-150"
              >
                <AiFillGithub size={20} />
                &nbsp;GitHub
              </a>
              <a
                href={getSocialLoginRoute("google")}
                className="rounded-full py-3 px-2 bg-white text-black flex items-center justify-center font-bold text-sm w-36 hover:bg-red-800 hover:text-white transition ease-in-out duration-150"
              >
                <AiOutlineGoogle size={20} />
                &nbsp;Google
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
