import axios from "axios";
import { getAuthCookie, removeAuthCookie } from "../utils/auth/cookies";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.headers.common = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

axios.interceptors.request.use(
  (config) => {
    const newConfig = config;
    if (getAuthCookie()) {
      newConfig.headers!.Authorization = `Bearer ${getAuthCookie()}`;
    }

    if (window.localStorage.getItem("Acc0")) {
      newConfig.headers!["DOCBLOC-ACC0"] = window.localStorage.getItem(
        "Acc0"
      ) as string;
    }

    return newConfig;
  },
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response.status === 401) {
      removeAuthCookie();
      window.localStorage.clear();
      window.location.replace("/");
    }

    if (err.response.status === 403) {
      switch (err.response.data.message) {
        case "Your email address is not verified.":
          // @ts-ignore
          window.location = "/not-verified";
          // @TODO fix
          break;
        case "select_account":
          // @ts-ignore
          window.location = "/";
          break;
        case "no_accounts":
          // @ts-ignore
          window.location = "/";
          break;
        default:
          break;
      }
    }

    if (err.response.status === 404) {
      // @ts-ignore
      window.location = "/e/404";
    }

    if (err.response.status === 503) {
      // @ts-ignore
      window.location = "/e/503";
    }

    if (err.response.status === 429) {
      // @ts-ignore
      window.location = "/e/429";
    }

    return Promise.reject(err);
  }
);

export default axios;
