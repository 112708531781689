import React from "react";
import LoadingComponent from "../components/LoadingComponent";

const AuthLayout: React.FC<{ loaded: boolean; children: any }> = ({
  children,
  loaded = true
}) => (
  <div className="h-screen flex overflow-hidden bg-gray-800 w-screen">
    {loaded ? children : <LoadingComponent />}
  </div>
);

export default AuthLayout;
