import DeleteProject from "../../../components/ProjectSettings/DeleteProject";
import ProjectSettingsLinks from "../../../components/ProjectSettings/LinksAndNavigation";
import ProjectSettingsAccess from "../../../components/ProjectSettings/UsersRolesAndAccess/Access";
import ProjectSettingsDetails from "../../../components/ProjectSettings/ProjectDetails";
import TransferProject from "../../../components/ProjectSettings/TransferProject";
import { ProjectSettingsMenuOption } from "../../../types";
import ProjectSettingsIAMUsers from "../../../components/ProjectSettings/UsersRolesAndAccess/Users";
import ProjectSettingsVersionDetails from "../../../components/ProjectSettings/Version/Details";
import ProjectSettingsLogos from "../../../components/ProjectSettings/DesignAndLayout/Logos";
import ProjectSettingsLayout from "../../../components/ProjectSettings/DesignAndLayout/Layout";
import DeleteProjectVersion from "../../../components/ProjectSettings/Version/DeleteVersion";
import ProjectSettingsDomains from "../../../components/ProjectSettings/Domain";

const settingsRoutes: ProjectSettingsMenuOption[] = [
  {
    title: "Current Version",
    children: [
      {
        title: "Details",
        path: "",
        subtitle:
          "These settings apply only to the current version of your documentation",
        Component: ProjectSettingsVersionDetails
      },
      {
        title: "Delete Version",
        path: "/delete-version",
        subtitle: "",
        Component: DeleteProjectVersion,
        condition: (project) => project.project.versions.length > 1
      }
    ]
  },
  {
    title: "General",
    children: [
      {
        title: "Project Details",
        path: "/details",
        subtitle:
          "Edit your project name, published version and availability here.",
        Component: ProjectSettingsDetails
      },
      {
        title: "Domain",
        path: "/domains",
        subtitle: "Edit your project domain and custom domains here.",
        Component: ProjectSettingsDomains
      },
      {
        title: "Links & Navigation",
        path: "/links",
        subtitle: "Manage your projects support url and links.",
        Component: ProjectSettingsLinks
      }
    ]
  },
  {
    title: "Design & Layout",
    children: [
      {
        title: "Logos & Icons",
        path: "/design/logos-and-icons",
        subtitle: "Edit your branding and icons here",
        Component: ProjectSettingsLogos
      },
      {
        title: "Layout",
        path: "/design/layout",
        subtitle: "Edit the layout of your documentation here",
        Component: ProjectSettingsLayout
      }
    ]
  },
  {
    title: "Users & Access",
    children: [
      {
        title: "Access",
        path: "/access",
        subtitle: "Access passwords for private documentation",
        Component: ProjectSettingsAccess,
        condition: (project) => project.project.visibility === "private"
      },
      {
        title: "Users",
        path: "/iam/users",
        subtitle: "Manage project user access",
        Component: ProjectSettingsIAMUsers
      }
      // {
      //   title: "Roles & Permissions",
      //   path: "/iam/roles-and-permissions",
      //   subtitle: "Create and manage project roles and permissions",
      //   Component: ProjectSettingsDetails,
      // },
    ]
  },
  {
    title: "Danger Zone",
    children: [
      {
        title: "Delete Project",
        path: "/delete",
        subtitle: "",
        Component: DeleteProject
      },
      {
        title: "Transfer Project",
        path: "/transfer",
        subtitle: "",
        Component: TransferProject
      }
    ]
  }
];

export default settingsRoutes;
