import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import {
  ACPColumnWidget,
  ACPImageWidget,
  ACPRowWidget,
  ACPTableWidget,
  ACPWidgetType,
  ACPWysiwygWidget
} from "../utils";
import ACPColumnEditorWidget from "./Column";
import AcpImageWidgetEditor from "./Image";
import AcpRowWidgetEditor from "./Row";
import AcpTableWidgetEditor from "./Table";
import AcpWysiwygWidgetEditor from "./Wysiwyg";

interface ACPGhostWidgetProps {
  index: number;
  value: ACPWidgetType;
  onChange: (val: ACPWidgetType) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  onDelete: () => void;
}

const ACPGhostWidget: React.FC<ACPGhostWidgetProps> = ({
  value: widget,
  onChange,
  index,
  onDelete
}) => {
  const sharedProps = {
    onChange,
    index,
    onDelete
  };
  switch (widget.type) {
    case "row":
      return (
        <AcpRowWidgetEditor value={widget as ACPRowWidget} {...sharedProps} />
      );
    case "wysiwyg":
      return (
        <AcpWysiwygWidgetEditor
          value={widget as ACPWysiwygWidget}
          {...sharedProps}
        />
      );
    case "image":
      return (
        <AcpImageWidgetEditor
          value={widget as ACPImageWidget}
          {...sharedProps}
        />
      );
    case "column":
      return (
        <ACPColumnEditorWidget
          value={widget as ACPColumnWidget}
          {...sharedProps}
        />
      );
    case "table":
      return (
        <AcpTableWidgetEditor
          value={widget as ACPTableWidget}
          {...sharedProps}
        />
      );
    default:
      return <div>not configured</div>;
  }
};

export default ACPGhostWidget;
